import * as React from 'react'

const SleepV2 = ({ size = 30, color = '#9F8EE6', ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0h30v30H0V0z" />
      <circle cx={16.773} cy={8.106} r={0.89} fill={color} />
      <circle cx={20.331} cy={9.885} r={0.89} fill={color} />
      <circle cx={22.11} cy={13.444} r={0.89} fill={color} />
      <path
        d="M22.44 16.013a7.63 7.63 0 01-12.996 4.694 7.632 7.632 0 014.694-12.995 5.935 5.935 0 008.302 8.301z"
        fill={color}
      />
    </svg>
  )
}

export default SleepV2
