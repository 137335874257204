const config = {
  environment: 'staging', // 'staging' 'production'
  healthie: {
    apiUrl: 'https://staging-api.gethealthie.com/graphql',
  },
  dataFunctions: {
    key: '31c49bf8-fced-4b1f-8de0-8e80faf3bdba', // firease api key deployed to firebase
    versionURL: 'https://us-central1-calibrate-reset.cloudfunctions.net/api/version', // cloud function that exposes current version of program data
    dataURL: 'https://us-central1-calibrate-reset.cloudfunctions.net/api/data', // cloud function that exposes program data
  },
}
export default config
