import * as React from 'react'

const MindSet2 = ({ size = 30, color = '#9F8EE6', ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0h30v30H0V0z" />
      <path
        d="M15 6c-4.418 0-8 3.505-8 7.829v3.857h2.319v2.723c0 1.022 1.275 1.248 2.203 1.248V25h7.813v-4.59C21.54 19.014 23 16.588 23 13.828 23 9.505 19.418 6 15 6z"
        fill={color}
      />
      <path
        d="M19.395 13.357c.786-.755.817-2.035.03-2.79a2.073 2.073 0 00-2.849 0l-.073.07-.064-.062a2.073 2.073 0 00-2.849 0 1.881 1.881 0 000 2.736l1.46 1.403.014.015 1.299 1.23a.153.153 0 00.206.002l1.16-1.053a82.228 82.228 0 001.22-1.122l.445-.429z"
        fill="#fff"
      />
    </svg>
  )
}

export default MindSet2
