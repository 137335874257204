import * as React from 'react'

const FoodV2 = ({ size = 30, color = '#9F8EE6', ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0h30v30H0z" />
      <path
        d="M19.182 8.016A2.824 2.824 0 1023.155 4l-3.973 4.016zM17.17 24.643a7.83 7.83 0 10-7.792-8.61 2.85 2.85 0 012.067 4.195 2.85 2.85 0 012.333 3.645 7.8 7.8 0 003.393.77zM7.67 18.425a1.35 1.35 0 112.56.85 1.35 1.35 0 01-2.56-.85zM9.77 22.625a1.35 1.35 0 112.561.85 1.35 1.35 0 01-2.561-.85zM5.57 22.625a1.35 1.35 0 112.56.85 1.35 1.35 0 01-2.56-.85z"
        fill={color}
      />
    </svg>
  )
}

export default FoodV2
