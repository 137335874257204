import React from 'react'

const ActivityV2 = ({ size = 30, color = '#9F8EE6', ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.597 16h-3.514l-2.636 5-5.271-10-2.636 5H6.025"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={6} cy={16.248} r={2} fill={color} />
      <circle cx={23.868} cy={16.248} r={2} fill={color} />
    </svg>
  )
}

export default ActivityV2
