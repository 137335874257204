// @ts-nocheck
import React, { useEffect, useMemo, useState, useReducer, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import _ from 'lodash'

import { useGetUser } from '@services/User'
import { useAppointmentsQuery, useAgendaMutation, useCommentMutation } from '@services/Appointments'
import ALoader from '@atoms/ALoader'

import OPageHeader from '@organisms/OPageHeader'

const APPOINTMENTS_FILTERS = {
  UPCOMING: 'upcoming',
  PAST: 'ended',
}

const ACTIONS = {
  SET_DETAILS: 'SET_DETAILS',
  CLEAR_SELECTED_SETAILS: 'CLEAR_SELECTED_SETAILS',
  RESET: 'RESET',
}

function init(initialValues) {
  return { ...initialValues }
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_DETAILS: {
      console.log('SET_DETAILS', action)
      return {
        ...state,
        detailsItem: action.payload.detailsItem,
      }
    }
    case ACTIONS.CLEAR_SELECTED_SETAILS: {
      return {
        ...state,
        detailsItem: null,
      }
    }
    case ACTIONS.RESET: {
      return init(action.payload)
    }
    default:
      throw new Error()
  }
}

const NO_SHOW_APPOINTMENT = 'No-Show'
const CANCELLED_APPOINTMENT = 'Cancelled'
const RESCHEDULED_APPOINTMENT = 'Re-Scheduled'

const APPOINTMENT_LABELS = {
  NO_SHOW: 'Missed',
  CANCELLED: 'Cancelled',
  RESCHEDULED: 'Rescheduled',
}

const checkAppointmentStatus = (status) => {
  switch (status) {
    case NO_SHOW_APPOINTMENT:
      return APPOINTMENT_LABELS.NO_SHOW
    case CANCELLED_APPOINTMENT:
      return APPOINTMENT_LABELS.CANCELLED
    case RESCHEDULED_APPOINTMENT:
      return APPOINTMENT_LABELS.RESCHEDULED
    default:
      return status
  }
}

function MAppointmentDetailsOverview({ appointment }) {
  const { startDateString, duration } = useMemo(() => {
    const startDateString = appointment.startAt.format('MMM D,  h:mm a')
    const duration = moment(appointment.endAt).diff(appointment.startAt, 'minute')
    return { startDateString, duration }
  }, [appointment])
  return (
    <>
      {/* Description list */}

      <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Start</dt>
            <dd className="mt-1 text-sm text-gray-900">{startDateString} </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Duration</dt>
            <dd className="mt-1 text-sm text-gray-900">{duration} min</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Type</dt>
            <dd className="mt-1 text-sm text-gray-900">{appointment.appointmentType}</dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Coach</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {appointment.coach.firstName} {appointment.coach.lastName}
            </dd>
          </div>
          {appointment.pm_status && (
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Status</dt>
              <dd className="mt-1 text-sm text-gray-900">{checkAppointmentStatus(appointment.pm_status)}</dd>
            </div>
          )}
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Agenda</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ReactMarkdown>{appointment.agenda}</ReactMarkdown>
            </dd>
          </div>
          <div className="sm:col-span-2">
            <dt className="text-sm font-medium text-gray-500">Coach comment</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <ReactMarkdown className="whitespace-pre-wrap">{appointment.comment}</ReactMarkdown>
            </dd>
          </div>
        </dl>
      </div>
    </>
  )
}

function MAppointmentAgendaOverview({ appointment, onClose, dispatch, userData, refetch }) {
  const [content, setContent] = useState('')
  const [agendaId, setAgendaId] = useState(appointment.agendaId || '')
  const [disabled, setDisabled] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const appointmentId = appointment.id
  const userId = userData.user.id

  const [mutateAgenda] = useAgendaMutation({
    appointmentId,
    userId,
  })

  const [mutateComment] = useCommentMutation({
    agendaId,
    content,
  })

  const finishForm = (agendaInfo) => {
    refetch()
    setDisabled(false)
    setIsSubmitted(false)

    const updatedAppointment = { ...appointment, ...agendaInfo }
    dispatch({
      type: ACTIONS.SET_DETAILS,
      payload: {
        detailsItem: updatedAppointment,
      },
    })
    onClose()
  }

  const onFormSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitted(true)
    setDisabled(true)

    let agendaInfo

    if (agendaId) {
      const commentRes = await mutateComment()
      agendaInfo = {
        comment: `${appointment.comment + '\n' + commentRes.data.createComment.newComment.content}`,
      }
    } else {
      const agendaRes = await mutateAgenda()
      const agendaId = agendaRes.data.createEntry.newEntry.id
      setAgendaId(agendaId)
      const commentResponse = await mutateComment()

      agendaInfo = {
        agenda: appointment.agenda || '',
        comment: commentResponse.data.createComment.newComment.content,
        agendaId,
        appointmentId,
      }
    }

    finishForm(agendaInfo)
  }

  return (
    <>
      {/* Textarea for agenda */}
      <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <p className="text-sm text-gray-700">Agenda: {appointment.agenda}</p>
        <form onSubmit={onFormSubmit}>
          <label className="mt-2 block text-sm text-gray-700">
            Comment:
            <textarea
              placeholder="Add a comment"
              disabled={isSubmitted}
              value={content}
              onChange={(e) => {
                setContent(e.target.value)
                setDisabled(e.target.value === '')
              }}
              className={
                disabled
                  ? `disabled:opacity-50 h-24 resize-none mt-2 w-full border rounded border-gray-300 shadow-sm`
                  : `h-24 resize-none mt-2 w-full border rounded border-gray-300 shadow-sm`
              }
            />
          </label>
          <div className="mt-1 flex">
            <button
              type="button"
              onClick={onClose}
              className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span>Cancel</span>
            </button>
            <button
              disabled={disabled || isSubmitted}
              type="submit"
              className={
                disabled
                  ? `disabled:opacity-50 ml-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`
                  : `ml-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`
              }
            >
              <svg
                className={
                  isSubmitted
                    ? `animate-spin -ml-1 mr-3 h-5 w-5 text-white`
                    : `hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white`
                }
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>

              <span>{isSubmitted ? 'Saving' : 'Save'}</span>
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

function OAppointmentsDetails({ appointment, onClose, userData, refetch, dispatch }) {
  const [whichTab, setWhichTab] = useState('overview')
  const [showAgenda, setShowAgenda] = useState(false)

  const tabActiveClassName =
    'border-secondary cursor-pointer  text-gray-900 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
  const tabInactiveClassName =
    'cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'

  return (
    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none order-last" tabIndex={0}>
      <article>
        {/* Tabs */}
        <div className="mt-6 sm:mt-2 2xl:mt-5">
          <div className="border-b border-gray-200">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-row justify-between">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {/* Current: "border-pink-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
                <div
                  onClick={() => {
                    setWhichTab('overview')
                  }}
                  className={whichTab === 'overview' ? tabActiveClassName : tabInactiveClassName}
                  aria-current="page"
                >
                  Overview
                </div>
              </nav>

              <div className="mt-1 flex items-center">
                <button
                  type="button"
                  onClick={onClose}
                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {/* Heroicon name: solid/phone */}
                  <svg
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  <span>Close</span>
                </button>
                <button
                  onClick={() => {
                    setShowAgenda(true)
                  }}
                  type="button"
                  className="ml-1 inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span>Add comment</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {whichTab === 'overview' && !showAgenda && <MAppointmentDetailsOverview appointment={appointment} />}
        {showAgenda && (
          <MAppointmentAgendaOverview
            appointment={appointment}
            onClose={() => {
              setShowAgenda(false)
            }}
            userData={userData}
            refetch={refetch}
            dispatch={dispatch}
          />
        )}
      </article>
    </main>
  )
}

function MAppointmentListItem({ appointment, onClick, isSelected, id, ...rest }) {
  const hasAvatar = useMemo(() => {
    return appointment?.coach?.avatarUrl && appointment?.coach?.avatarUrl !== 'original/missing.png'
  }, [appointment?.coach?.avatarUrl])

  const fullDateString = useMemo(() => {
    const startDateString = appointment.startAt.format('MMM D,  h:mm a')
    const duration = moment(appointment.endAt).diff(appointment.startAt, 'minute')
    return `${startDateString} (${duration} min)`
  }, [appointment])

  return (
    <li
      className={`relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 ${
        isSelected && 'bg-gray-100'
      }`}
    >
      <div className="flex-1 min-w-0">
        <div onClick={onClick} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true"></span>
          <p className="text-sm text-gray-700 truncate">{`${appointment.coach.firstName} ${appointment.coach.lastName}`}</p>
          <p className={`text-sm truncate text-gray-500`}>{appointment.appointmentType}</p>
          <p className="text-sm font-medium text-gray-900">{fullDateString}</p>
          <p className={`text-sm truncate text-gray-600`}>{appointment.agenda}</p>
          {appointment.pm_status && (
            <p className={`mt-2 text-sm font-medium text-purple-600`}>
              {checkAppointmentStatus(appointment.pm_status)}
            </p>
          )}
        </div>
      </div>
    </li>
  )
}

export default function PAppointments() {
  const { userId } = useParams()
  const [state, dispatch] = useReducer(reducer, {}, init)
  const [appointmentsFilter, setAppointmentsFilter] = useState(APPOINTMENTS_FILTERS.UPCOMING)
  const { data, loading, error, refetchNotes } = useAppointmentsQuery({
    userId,
    filter: appointmentsFilter,
  })

  const { localData, detailsItem } = state
  const { data: userData } = useGetUser(userId)

  const dispatchNewData = useCallback(
    (data) => {
      if (!_.isEqual(localData, data)) {
        const newData = _.cloneDeep(data)
        dispatch({
          type: ACTIONS.RESET,
          payload: { localData: newData, detailsItem: detailsItem },
        })
      }
    },
    [localData, detailsItem]
  )

  useEffect(() => {
    if (!loading && data) {
      console.log('dispatch new data', data)
      dispatchNewData(data)
    }
  }, [data, dispatchNewData, loading])

  const { fullName, dob } = useMemo(() => {
    let fullName = 'Loading...'
    let dob = null
    if (userData?.user) {
      const dobInputString = userData?.user?.dob
      fullName = userData?.user?.full_legal_name
      if (dobInputString) {
        dob = moment(dobInputString).format('MM / DD / YYYY')
      }
    }
    return { fullName, dob }
  }, [userData])

  if (loading) return <p>Loading...</p>
  if (error) return <p>Oh no... {error}</p>
  return (
    <>
      <div className="h-screen overflow-hidden bg-white">
        <div className="flex flex-col h-screen overflow-hidden">
          <OPageHeader />
          <div className="flex min-h-0 flex-col min-w-0 flex-1">
            <div className="flex-1 relative z-0 flex overflow-hidden">
              {detailsItem && (
                <OAppointmentsDetails
                  key={detailsItem.id}
                  appointment={detailsItem}
                  onClose={() => {
                    // @ts-ignore
                    dispatch({
                      type: ACTIONS.CLEAR_SELECTED_SETAILS,
                    })
                  }}
                  userData={userData}
                  refetch={refetchNotes}
                  dispatch={dispatch}
                />
              )}
              <aside className="order-first flex flex-col flex-shrink-0 w-2/5 border-r border-gray-200 relative">
                <div className="px-6 pt-6 pb-4 flex justify-between items-center">
                  <div>
                    <h2 className="text-lg font-medium text-gray-900">{fullName}</h2>
                    <p className="text-base text-gray-600">{dob ? dob : 'Missing DOB'}</p>
                  </div>
                </div>
                {/* Directory list */}
                <nav className="flex-1 min-h-0 overflow-y-auto relative" aria-label="Directory">
                  <div>
                    <div className="hidden sm:block">
                      <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
                        {/* Current: "text-gray-900", Default: "text-gray-500 hover:text-gray-700" */}
                        <div
                          onClick={() => {
                            setAppointmentsFilter(APPOINTMENTS_FILTERS.UPCOMING)
                          }}
                          className={`${
                            appointmentsFilter === APPOINTMENTS_FILTERS.UPCOMING
                              ? 'text-gray-900 rounded-l-lg'
                              : 'text-gray-500 hover:text-gray-700'
                          } cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10`}
                        >
                          <span>Upcoming</span>
                          <span
                            aria-hidden="true"
                            className={`${
                              appointmentsFilter === APPOINTMENTS_FILTERS.UPCOMING ? 'bg-indigo-500' : 'bg-transparent'
                            } absolute inset-x-0 bottom-0 h-0.5`}
                          ></span>
                        </div>

                        <div
                          onClick={() => {
                            setAppointmentsFilter(APPOINTMENTS_FILTERS.PAST)
                          }}
                          className={`${
                            appointmentsFilter === APPOINTMENTS_FILTERS.PAST
                              ? 'text-gray-900 rounded-l-lg'
                              : 'text-gray-500 hover:text-gray-700'
                          } cursor-pointer group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10`}
                        >
                          <span>Past</span>
                          <span
                            aria-hidden="true"
                            className={`${
                              appointmentsFilter === APPOINTMENTS_FILTERS.PAST ? 'bg-indigo-500' : 'bg-transparent'
                            } absolute inset-x-0 bottom-0 h-0.5`}
                          ></span>
                        </div>
                      </nav>
                    </div>
                  </div>
                  <ul className="relative z-0 divide-y divide-gray-200">
                    {localData?.map((appointment, index) => (
                      <MAppointmentListItem
                        key={appointment.id}
                        id={appointment.id}
                        appointment={appointment}
                        isSelected={detailsItem ? Boolean(appointment?.id === detailsItem?.id) : false}
                        onClick={() => {
                          // @ts-ignore
                          dispatch({
                            type: ACTIONS.SET_DETAILS,
                            payload: {
                              detailsItem: appointment,
                            },
                          })
                        }}
                      />
                    ))}
                  </ul>
                </nav>

                {/* {anyChanges && (
              <button
                type="button"
                disabled={false}
                onClick={() => {
                  alert('SAVE')
                }}
                className={`z-50 absolute bottom-6 right-6 flex-row items-center inline-flex justify-center py-4 px-4 border border-transparent text-sm font-medium text-white bg-secondary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-full`}
              >
                <Save />
                <span className="ml-2">Save changes</span>
              </button>
            )} */}
              </aside>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
