import * as React from 'react'

const CalibrateV2 = ({ size = 30, color = '#9F8EE6', ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0h30v30H0V0z" />
      <path
        d="M16.628 17.436h-2.152V25h2.152v-7.564zM11.149 14.12H9v10.854h2.149V14.119zM22 20.383h-2.149v4.572H22v-4.572zM15.554 13.475l4.28-6.356h-8.56l4.28 6.356z"
        fill={color}
      />
    </svg>
  )
}

export default CalibrateV2
