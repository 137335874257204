import { useQuery, gql } from '@apollo/client'
import moment from 'moment'
import { getFormattedHealthieDate } from '@utils/dateTime'

import { getFbProgramClassById, getFbPillarById } from '@utils/programClasses'

import { GOALS_DATA } from '@constants/Queries'

const defaultGoalsVariables = {
  date_range_is_selected: true,
  status_filter: 'all',
  sort_by: 'end_date_asc',
  frequency_filter: 'all',
}

export const useGoalsData = (variables = {}) => {
  const { start_range, end_range, healthieUserId } = variables
  const { data, loading, error } = useQuery(GOALS_DATA, {
    variables: {
      ...defaultGoalsVariables,
      user_id: healthieUserId,
      start_range: moment(start_range).format('YYYY-MM-DD'),
      end_range: moment(end_range).format('YYYY-MM-DD'),
      per_page: 400,
    },
  })
  const dataToReturn = prepareGoals(data?.goalsData?.goals)
  return { data: dataToReturn, loading, error }
}

/**
 * @return {Goal[]}
 */
export const prepareGoals = (goals, firebaseData) => {
  if (!goals) {
    return []
  }
  return goals.map((goal) => prepareGoal(goal, firebaseData)).filter((goal) => goal)
}

/**
 * @return {Goal}
 */
const prepareGoal = (goal, firebaseData) => {
  if (!goal) return null

  let description = ''
  let question = ''
  let courseId = ''
  if (goal.description) {
    const descriptionMarkup = goal.description.split('~~~').map((val) => val.trim().replace('course_id: ', ''))

    description = descriptionMarkup[0]
    question = descriptionMarkup[1]
    courseId = descriptionMarkup[2]
  }

  const fbProgramClass = getFbProgramClassById(firebaseData, courseId)
  const pillar = fbProgramClass && fbProgramClass?.pillarId && getFbPillarById(firebaseData, fbProgramClass.pillarId)

  return {
    ...goal,
    isCompletedForDate: goal.is_completed_for_date,
    startOn: moment(goal.start_on),
    dueAt: goal.due_date ? moment(goal.due_date) : null,
    name: goal.name,
    description,
    question,
    courseId,
    pillar,
  }
}

export const deleteUserGoals = async (userGoals, apolloClient) => {
  const goals = userGoals?.goalsData?.goals
  if (!goals) {
    return
  }
  const deleteUserGoalsMutation = goals.reduce((aggr, goal, index) => {
    const deleteGoal = `deleteGoal${index}: deleteGoal(
      input: {
        id: "${goal.id}"
      }
    ) {
      goal {
        id
      }
    }
    `

    return aggr + deleteGoal
  }, '')

  const deleteUserGoalsMutationFinal = gql`mutation deleteMulitpleGoals{${deleteUserGoalsMutation}}`

  const mutationResult = await apolloClient.mutate({
    mutation: deleteUserGoalsMutationFinal,
  })

  // return mutationResult
  return Promise.resolve(mutationResult)
}
