import React from 'react'

const CalibrateV1 = ({ size = 100, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="#148E86"
        d="M76.181 84.196H63.435V129h12.746zM43.727 64.553H31v64.291h12.727zM108 101.655H95.273v27.078H108z"
      />
      <path d="M69.818 60.734L95.17 23.087H44.467l25.351 37.647z" fill="#9F8EE6" />
    </svg>
  )
}

export default CalibrateV1
