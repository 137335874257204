import React, { useContext, useEffect, useMemo } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import moment from 'moment'
import { ApolloProvider } from '@apollo/client'
import apolloClient from '@services/ApolloClient'
import { AppStatesContext, AppStatesProvider } from '@services/AppStates'

import PProgramManagement from '@pages/PProgramManagement'
import PAppointments from '@pages/PAppointments'
import PSignIn from '@pages/PSignIn'

function App() {
  const { updateValue, healthieToken } = useContext(AppStatesContext)

  useEffect(() => {
    updateValue(
      // @ts-ignore
      'dateNow',
      moment().startOf('day').add(1, 'second').toDate()
    )
  }, [updateValue])

  const isSignedIn = useMemo(() => {
    return Boolean(healthieToken)
  }, [healthieToken])

  if (!isSignedIn) {
    return <PSignIn />
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/user/:userId">
          <PProgramManagement />
        </Route>
        <Route exact path="/user/:userId/appointments">
          <PAppointments />
        </Route>
        <Route path="/">
          <div>Wrong URL</div>
        </Route>
      </Switch>
    </Router>
  )
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <AppStatesProvider>
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </AppStatesProvider>
  )
}
