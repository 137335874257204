import * as React from 'react'

const Weight2 = ({ size = 30, color = '#9F8EE6', ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0h30v30H0V0z" fill="#fff" />
      <path
        d="M18.143 7H21a2 2 0 012 2v12a2 2 0 01-2 2H9a2 2 0 01-2-2V9a2 2 0 012-2h2.857"
        stroke={color}
        strokeWidth={1.5}
      />
      <rect x={7} y={7} width={16} height={16} rx={3} fill={color} />
      <circle cx={15} cy={8} r={4.25} fill="#fff" stroke={color} strokeWidth={1.5} />
      <circle cx={15} cy={8} r={1} fill={color} />
      <rect
        x={12.693}
        y={6.577}
        width={1.25}
        height={2.227}
        rx={0.625}
        transform="rotate(-45 12.693 6.577)"
        fill={color}
      />
    </svg>
  )
}

export default Weight2
