import React, { useState, useCallback, createContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isEqual } from 'lodash'

import { getHealthieStorageUser } from './User'

export const initialValues = {
  dateNow: moment().startOf('day').add(1, 'second').toDate(), // moment('2020-12-08T20:31:00-0800').toDate()
  updateValue: (key, value) => {},
  currentProgramClass: null,
  isCurrentProgramClassLoading: false,
  isOnboardingShown: false,
  testOnboardingFinishSetup: true,
  isWelcomeKitReceivedModalShown: false,
  // FIXME
  programStartDate: moment('2018-01-01'),
  programEndDate: moment('2030-12-30'),
  healthieToken: null,
}

export const AppStatesContext = createContext(initialValues)

export const AppStatesProvider = ({ children }) => {
  const [values, setValues] = useState(initialValues)
  const getDateNowWithTime = useCallback(
    (date) => {
      const now = date ? moment(date) : moment()
      return moment(values.dateNow)
        .set('hours', now.get('hours'))
        .set('minutes', now.get('minutes'))
        .set('seconds', now.get('seconds'))
        .toDate()
    },
    [values.dateNow]
  )

  const updateValue = useCallback((key, value) => {
    setValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }))
  }, [])

  const refreshHealthieToken = useCallback(async () => {
    const user = await getHealthieStorageUser()
    updateValue('healthieToken', user?.token)
  }, [updateValue])

  useEffect(() => {
    if (updateValue) refreshHealthieToken()
  }, [refreshHealthieToken, updateValue])

  const resetStates = useCallback(() => {
    if (!isEqual(values, initialValues)) {
      setValues(initialValues)
    }
  }, [values])

  return (
    <AppStatesContext.Provider
      value={{
        ...values,
        resetStates,
        getDateNowWithTime,
        updateValue,
        refreshHealthieToken,
      }}
    >
      {children}
    </AppStatesContext.Provider>
  )
}

AppStatesProvider.propTypes = {
  children: PropTypes.node,
}
