import React from 'react'

const ActivitySet1 = ({ size = 100, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.472 37c.766 0 1.47.425 1.826 1.104L86.34 97.087l14.608-27.756a2.064 2.064 0 011.827-1.104h21.912c1.14 0 2.065.926 2.065 2.068a2.067 2.067 0 01-2.065 2.069H104.02l-15.853 30.123a2.064 2.064 0 01-3.653 0L53.472 43.503 38.864 71.26a2.064 2.064 0 01-1.826 1.103H15.125a2.067 2.067 0 01-2.065-2.068c0-1.142.925-2.068 2.065-2.068h20.667l15.853-30.123A2.064 2.064 0 0153.472 37z"
        fill="#148E86"
      />
      <path
        d="M18 69.684a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM130 69.684c0 2.485-2.239 4.5-5 4.5s-5-2.015-5-4.5 2.239-4.5 5-4.5 5 2.015 5 4.5z"
        fill="#FAB52F"
      />
    </svg>
  )
}

export default ActivitySet1
