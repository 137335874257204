import * as React from 'react'

const FoodV1 = ({ size = 100, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M80.605 126.857c25.739 0 46.605-20.866 46.605-46.605 0-25.739-20.866-46.604-46.605-46.604-25.739 0-46.604 20.865-46.604 46.604 0 2.397.18 4.75.53 7.05a11.806 11.806 0 0110.153-1.293c6.199 2.06 9.554 8.754 7.495 14.953a11.82 11.82 0 01-8.464 7.774c.44.569.893 1.128 1.359 1.676a11.824 11.824 0 0111.61-2.403c6.2 2.06 9.555 8.755 7.495 14.954-.09.273-.19.541-.299.803a46.484 46.484 0 0016.725 3.091z"
        fill="#148E86"
      />
      <path
        d="M114.786 26.225c-5.15 5.204-13.243 5.727-18.983 1.54l20.321-20.538c4.248 5.695 3.811 13.793-1.338 18.998z"
        stroke="#FAB52F"
        strokeWidth={4.398}
      />
      <path
        d="M48.005 99.576a7.43 7.43 0 11-14.101-4.685 7.43 7.43 0 1114.101 4.685zM33.005 121.821a7.43 7.43 0 11-14.102-4.685 7.43 7.43 0 0114.102 4.685zM50.612 126.284a7.43 7.43 0 104.685-14.101 7.43 7.43 0 10-4.685 14.101z"
        fill="#9F8EE6"
      />
    </svg>
  )
}

export default FoodV1
