import gql from 'graphql-tag'

export const SIGN_IN = gql`
  mutation signIn(
    $email: String, 
    $password: String, 
    $generate_api_token: Boolean
  ) {
    signIn(
      input: { 
        email: $email, 
        password: $password, 
        generate_api_token: 
        $generate_api_token 
    }) {
      api_key
      token
      user {
        id
        email
        timezone
      }
    }
  }
`

export const CREATE_FORM_ANSWER_GROUP = gql`
  mutation createFormAnswerGroup(
    $course_item_id: ID
    $finished: Boolean
    $custom_module_form_id: String
    $user_id: String
    $form_answers: [FormAnswerInput!]!
    $course_id: String
  ) {
    createFormAnswerGroup(
      input: {
        course_item_id: $course_item_id
        custom_module_form_id: $custom_module_form_id
        user_id: $user_id
        course_id: $course_id
        form_answers: $form_answers
        finished: $finished
      }
    ) {
      newFormAnswerGroup: form_answer_group {
        id
        name
        created_at
        updated_at
        finished
        user {
          id
          next_required_step
          __typename
        }
        __typename
      }
      __typename
    }
  }
`

export const COMPLETE_COURSE_ITEM = gql`
  mutation completeCourseItem(
    $user_id: String
    $course_item_id: String
    $completed_item_id: String
    $completed_item_type: String
  ) {
    createCompletedCourseItem(
      input: {
        user_id: $user_id
        course_item_id: $course_item_id
        completed_item_id: $completed_item_id
        completed_item_type: $completed_item_type
      }
    ) {
      completedCourseItem {
        __typename
        id
        updated_at
      }
    }
  }
`

export const CREATE_ENTRY = gql`
  mutation createEntry(
    $description: String
    $metric_stat: String
    $category: String
    $created_at: String
    $type: String = "MetricEntry"
    $third_party_source: String
  ) {
    createEntry(
      input: {
        description: $description
        category: $category
        type: $type
        metric_stat: $metric_stat
        created_at: $created_at
        third_party_source: $third_party_source
      }
    ) {
      newEntry: entry {
        __typename
        id
        description
        category
        created_at
        type
        name
        viewed
        metric_stat
        added_by_user {
          id
          full_name
          avatar_url
        }
        source
      }
    }
  }
`

export const CREATE_FOOD_ENTRY = gql`
  mutation createFoodEntry(
    $created_at: String
    $image: Upload
    $description: String
    $ed_prehunger: String
    $ed_posthunger: String
    $reflection: String
    $emotions: String
    $category: String
  ) {
    createEntry(
      input: {
        created_at: $created_at
        image: $image
        description: $description
        metric_stat: "2"
        ed_prehunger: $ed_prehunger
        ed_posthunger: $ed_posthunger
        reflection: $reflection
        emotions: $emotions
        category: $category
        type: "FoodEntry"
      }
    ) {
      newEntry: entry {
        __typename
        id
        description
        category
        created_at
        type
        name
        viewed
        metric_stat
        added_by_user {
          id
          full_name
          avatar_url
          __typename
        }
        source
      }
    }
  }
`

export const UPDATE_FOOD_ENTRY = gql`
  mutation updateFoodEntry(
    $id: ID
    $created_at: String
    $image: Upload
    $description: String
    $ed_prehunger: String
    $ed_posthunger: String
    $reflection: String
    $emotions: String
    $category: String
  ) {
    updateEntry(
      input: {
        id: $id
        created_at: $created_at
        image: $image
        description: $description
        ed_prehunger: $ed_prehunger
        ed_posthunger: $ed_posthunger
        reflection: $reflection
        emotions: $emotions
        category: $category
      }
    ) {
      updatedEntry: entry {
        __typename
        id
        description
        category
        created_at
        type
        name
        viewed
        metric_stat
        added_by_user {
          id
          full_name
          avatar_url
        }
        source
      }
    }
  }
`

export const CREATE_ENTRY_WITH_SUBENTRIES = gql`
  mutation createEntry(
    $description: String
    $subentries: [SubentryInput!]!
    $category: String
    $created_at: String
    $type: String = "MetricEntry"
  ) {
    createEntry(
      input: {
        description: $description
        subentries: $subentries
        category: $category
        created_at: $created_at
        type: $type
      }
    ) {
      newEntry: entry {
        __typename
        id
        description
        category
        created_at
        type
        name
        viewed
        metric_stat
        added_by_user {
          id
          full_name
          avatar_url
          __typename
        }
        source
      }
    }
  }
`

export const UPDATE_ENTRY = gql`
  mutation updateEntry(
    $id: ID, 
    $description: String, 
    $metric_stat: String, 
    $category: String, 
    $created_at: String
  ) {
    updateEntry(
      input: {
        id: $id
        description: $description
        category: $category
        metric_stat: $metric_stat
        created_at: $created_at
      }
    ) {
      newEntry: entry {
        __typename
        id
        description
        category
        created_at
        type
        name
        viewed
        metric_stat
        added_by_user {
          id
          full_name
          avatar_url
          __typename
        }
        source
      }
    }
  }
`

export const DELETE_ENTRY = gql`
  mutation deleteEntry($id: ID, $clientMutationId: String) {
    deleteEntry(input: { id: $id, clientMutationId: $clientMutationId }) {
      deletedEntry: entry {
        id
        metric_stat
        category
        __typename
      }
    }
  }
`

export const UPDATE_USER_TIMEZONE = gql`
  mutation updateUser($timezone: String) {
    updateUser(input: { timezone: $timezone }) {
      user {
        next_required_step
        blast_seen
        id
        email
        consented_to_labs
        skipped_email
        __typename
      }
      messages {
        field
        message
        __typename
      }
      __typename
    }
  }
`

export const UPDATE_USER_AVATAR = gql`
  mutation updateUser($avatar: Upload) {
    updateUser(input: { avatar: $avatar }) {
      user {
        next_required_step
        blast_seen
        id
        email
        consented_to_labs
        skipped_email
        __typename
      }
      messages {
        field
        message
        __typename
      }
      __typename
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($email: String) {
    resetPassword(input: { email: $email }) {
      messages {
        message
        field
        __typename
      }
      __typename
    }
  }
`

export const CREATE_GOAL_HISTORY = gql`
  mutation createGoalHistory(
    $user_id: String
    $goal_id: String
    $mark_parent_complete: Boolean
    $completed_on: String
  ) {
    createGoalHistory(
      input: {
        user_id: $user_id
        goal_id: $goal_id
        mark_parent_complete: $mark_parent_complete
        completed_on: $completed_on
      }
    ) {
      clientMutationId
      goalHistory {
        __typename
        completed_on
        name
        goal {
          id
        }
      }
      __typename
    }
  }
`

export const DELETE_GOAL_HISTORY = gql`
  mutation deleteGoalHistory(
    $id: ID, 
    $completed_on: String
  ) {
    deleteGoalHistory(
      input: { 
        id: $id, 
        completed_on: $completed_on 
      }
    ) {
      goal_history {
        __typename
        completed_on
        name
        goal {
          id
        }
      }
      messages {
        field
        message
        __typename
      }
      __typename
    }
  }
`

export const CREATE_GOOGLE_FIT = gql`
  mutation createGoogleFit(
    $user_id: ID
    $clientMutationId: String
    $access_token: String
    $refresh_token: String
    $expiry_date: String
  ) {
    createGoogleFit(
      input: {
        clientMutationId: $clientMutationId
        is_enabled: true
        user_id: $user_id
        access_token: $access_token
        refresh_token: $refresh_token
        expiry_date: $expiry_date
      }
    ) {
      google_fit {
        id
      }
      __typename
    }
  }
`

export const UPDATE_GOOGLE_FIT = gql`
  mutation updateGoogleFit(
    $google_fit_id: ID, 
    $access_token: String, 
    $refresh_token: String, 
    $expiry_date: String
  ) {
    updateGoogleFit(
      input: {
        id: $google_fit_id
        is_enabled: true
        access_token: $access_token
        refresh_token: $refresh_token
        expiry_date: $expiry_date
      }
    ) {
      google_fit {
        id
      }
      __typename
    }
  }
`

export const DELETE_GOOGLE_FIT = gql`
  mutation deleteGoogleFit($google_fit_id: ID) {
    deleteGoogleFit(input: { id: $google_fit_id }) {
      google_fit {
        id
      }
      __typename
    }
  }
`

export const CREATE_NOTE = gql`
  mutation createEntry(
    $description: String
    $metric_stat: String
    $category: String
    $type: String
    $image: Upload
    $created_at: String
    $user_id: String
    $percieved_hungriness: String
    $ed_prehunger: String
    $ed_posthunger: String
    $subentries: [SubentryInput]
    $emotions: String
    $reflection: String
  ) {
    createEntry(
      input: {
        description: $description
        category: $category
        type: $type
        image: $image
        metric_stat: $metric_stat
        user_id: $user_id
        created_at: $created_at
        percieved_hungriness: $percieved_hungriness
        ed_prehunger: $ed_prehunger
        ed_posthunger: $ed_posthunger
        subentries: $subentries
        emotions: $emotions
        reflection: $reflection
      }
    ) {
      newEntry: entry {
        ...EntryFragment
        __typename
      }
      messages {
        field
        message
        __typename
      }
      __typename
    }
  }

  fragment EntryFragment on Entry {
    id
    description
    category
    reflection
    created_at
    type
    name
    viewed
    metric_stat
    percieved_hungriness
    ed_prehunger_string
    ed_posthunger_string
    emotions_string
    image_url
    healthiness_info_hex_value
    ed_prehunger
    ed_posthunger
    has_subentries
    subentries {
      id
      category
      type
      description
      metric_stat
      __typename
    }
    poster {
      id
      full_name
      avatar_url
      last_activity
      __typename
    }
    added_by_user {
      id
      full_name
      avatar_url
      __typename
    }
    source
    third_party_source
    comments {
      ...CommentFragment
      __typename
    }
    __typename
  }

  fragment CommentFragment on Comment {
    id
    content
    created_at
    is_reaction
    poster {
      id
      full_name
      avatar_url
      __typename
    }
    __typename
  }
`

export const UPDATE_NOTE = gql`
  mutation updateEntry(
    $id: ID
    $description: String
    $category: String
    $viewed: Boolean
    $hide_from_main_feed: Boolean
    $metric_stat: String
    $percieved_hungriness: String
    $ed_prehunger: String
    $ed_posthunger: String
    $emotions: String
    $reflection: String
    $created_at: String
    $subentries: [SubentryInput]
  ) {
    updateEntry(
      input: {
        id: $id
        description: $description
        category: $category
        viewed: $viewed
        hide_from_main_feed: $hide_from_main_feed
        metric_stat: $metric_stat
        percieved_hungriness: $percieved_hungriness
        ed_prehunger: $ed_prehunger
        ed_posthunger: $ed_posthunger
        emotions: $emotions
        reflection: $reflection
        created_at: $created_at
        subentries: $subentries
      }
    ) {
      entry {
        ...EntryForEditingFragment
        __typename
      }
      messages {
        field
        message
        __typename
      }
      __typename
    }
  }

  fragment EntryForEditingFragment on Entry {
    id
    description
    category
    viewed
    type
    name
    metric_stat
    created_at
    image
    percieved_hungriness
    ed_prehunger
    ed_posthunger
    emotions
    reflection
    subentries {
      id
      description
      metric_stat
      category
      type
      __typename
    }
    __typename
  }
`

export const CREATE_COMMENT = gql`
  mutation createComment(
    $entry_id: ID, 
    $content: String, 
    $user_id: ID, 
    $is_reaction: Boolean
  ) {
    createComment(
      input: { 
        entry_id: $entry_id, 
        content: $content, 
        user_id: $user_id, 
        is_reaction: $is_reaction
      }
    ) {
      newComment: comment {
        ...CommentFragment
        __typename
      }
      messages {
        field
        message
        __typename
      }
      __typename
    }
  }
  fragment CommentFragment on Comment {
    id
    content
    created_at
    is_reaction
    poster {
      id
      full_name
      avatar_url
      __typename
    }
    __typename
  }
`
