import { useMutation, useQuery } from '@apollo/client'
import { APPOINTMENTS, NOTE_ENTRIES } from '@constants/Queries'
import { getFormattedHealthieDate, getHealthieDateFormat } from '@utils/dateTime'
import { CREATE_NOTE, UPDATE_NOTE, CREATE_COMMENT } from '@constants/Mutations'
import moment from 'moment'

const matter = {
  stringify: (content, params) => {
    return `
    ${content}
    ---
    ${Object.entries(params)
      .map(([key, value]) => {
        return [key, value].join(': ')
      })
      .join('\n')}
    `
  },
  parse: (str = '') => {
    const [content = '', param = ''] = (str.split('---') || []).map((part) => part.trim())
    // .filter(Boolean)
    // removed so we can display comments without agenda title

    return {
      content,
      params: param === null ? {} : Object.fromEntries([param.split(': ')]),
    }
  },
}

const prepareAgendas = (entries) => {
  return entries
    .map((entry) => {
      const { content, params } = matter.parse(entry.reflection)
      return {
        agendaId: entry.id,
        agenda: content,
        appointmentId: params?.appointmentId ?? null,
        comment: entry.comments
          .map((comment) => {
            const { content = '' } = comment
            return content.trim()
          })
          .join('\n'),
      }
    })
    .filter((entry) => Boolean(entry.appointmentId))
}

export const useNotesQuery = ({ userId }) => {
  const variables = {
    client_id: userId,
    entry_id: null,
    keywords: null,
    type: 'NoteEntry',
  }
  const {
    data: { entries = [] } = {},
    refetch,
    networkStatus,
  } = useQuery(NOTE_ENTRIES, {
    variables,
    notifyOnNetworkStatusChange: true,
  })

  return { data: prepareAgendas(entries), refetch, networkStatus }
}

//:FIX: Make sure to store for a userId, not for the user with the token
export const useAgendaMutation = ({ content = '', appointmentId = null, agendaId = false, userId }) => {
  if (!appointmentId) {
    throw new Error('appointmentId must be provided.')
  }
  const reflection = matter.stringify(content.trim(), { appointmentId })
  const variables = {
    created_at: getHealthieDateFormat(new Date()),
    diastolic_bp: '',
    systolic_bp: '',
    type: 'NoteEntry',
    reflection,
    user_id: userId,
  }
  if (agendaId) {
    variables.id = agendaId
  }

  return useMutation(agendaId ? UPDATE_NOTE : CREATE_NOTE, {
    variables,
  })
}

export const useAppointmentsQuery = ({ userId, filter = 'upcoming', options = { variables: {} } }) => {
  const { variables = {}, ...rest } = options

  const {
    data: { appointments = [] } = { appointments: [] },
    loading,
    error,
    refetch,
  } = useQuery(APPOINTMENTS, {
    variables: {
      user_id: userId,
      filter,
      sort_by: filter === 'ended' ? 'date_desc' : 'date_asc',
      should_paginate: false,
      with_all_statuses: true,
      ...variables,
    },
    ...rest,
    notifyOnNetworkStatusChange: true,
  })
  const { data: notes, refetch: refetchNotes, networkStatus } = useNotesQuery({ userId })
  const data = (appointments?.map(prepareAppointment) ?? []).map((appointment) => {
    const note = notes.find((note) => note.appointmentId === appointment.id) || {}
    return {
      ...appointment,
      ...note,
    }
  })
  return { data, loading, error, refetch, refetchNotes, networkStatus }
}

const prepareAppointment = (appointment) => {
  if (!appointment) {
    return null
  }

  const {
    id,
    use_zoom,
    zoom_join_url,
    start,
    end,
    provider: { avatar_url, first_name, last_name },
    appointment_type: { name },
    contact_type,
    pm_status,
  } = appointment

  const coach = {
    avatarUrl: avatar_url,
    firstName: first_name,
    lastName: last_name,
  }

  return {
    id,
    coach,
    appointmentType: name,
    contactType: contact_type,
    zoomLink: use_zoom && zoom_join_url,
    start: getFormattedHealthieDate(start),
    startAt: moment(start),
    end: getFormattedHealthieDate(end),
    endAt: moment(end),
    pm_status,
  }
}

export const useCommentMutation = ({ content, agendaId }) => {
  const variables = {
    entry_id: agendaId,
    content: content,
  }

  return useMutation(CREATE_COMMENT, {
    variables,
  })
}
