import { useEffect, useMemo, useContext } from 'react'
import { useQuery, useLazyQuery, useMutation, useApolloClient } from '@apollo/client'
import { GET_USER, CURRENT_USER } from '@constants/Queries'
import { SIGN_IN } from '@constants/Mutations'
import { AppStatesContext } from './AppStates'

const HEALTHIE_USER = 'HEALTHIE_USER'

let healthieUser = null

/**
 * Get user data by id
 * @param {*} healthieUserId
 * @returns
 */
export const useGetUser = (healthieUserId) => {
  const { data = {}, loading, error } = useQuery(GET_USER, {
    variables: { id: healthieUserId },
  })
  return { data, loading, error }
}

/**
 * Get current logged in user data
 * @returns
 */
export const useCurrentHealhtieUser = () => {
  const { healthieToken } = useContext(AppStatesContext)
  const [getCurrentUser, { data, loading, error }] = useLazyQuery(CURRENT_USER, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    // run on every helthieToken update
    if (healthieToken) {
      getCurrentUser()
    }
  }, [getCurrentUser, healthieToken])

  const dataToReturn = useMemo(() => (healthieToken ? data : { currentUser: null }), [data, healthieToken])

  return {
    data: dataToReturn,
    loading,
    error,
    refetch: async () => {
      await getCurrentUser()
    },
  }
}

export const setHealthieStorageUser = async (user) => {
  if (!user) return Promise.reject()

  healthieUser = JSON.stringify(user)

  return localStorage.setItem(HEALTHIE_USER, healthieUser)
}

export const getHealthieStorageUser = async () => {
  const storageUser = localStorage.getItem(HEALTHIE_USER)

  if (storageUser) {
    healthieUser = storageUser
  }

  return JSON.parse(storageUser)
}

export const removeHealthieStorageUser = async () => {
  localStorage.removeItem(HEALTHIE_USER)
  return Promise.resolve()
}

export const useSignInMutation = () => {
  const [signInMutation] = useMutation(SIGN_IN)
  const { refreshHealthieToken } = useContext(AppStatesContext)

  return [
    async ({ email, password }) => {
      try {
        const { data: signInData } = await signInMutation({
          variables: {
            email,
            password,
            generate_api_token: true,
          },
        })

        const healthieToken = signInData.signIn.api_key

        const healthieId = signInData?.signIn?.user?.id
        await setHealthieStorageUser({
          token: healthieToken,
          email,
          password,
          healthieId,
        })
        await refreshHealthieToken()

        return Promise.resolve(signInData.signIn)
      } catch (err) {
        console.log(err)

        return Promise.reject(err)
      }
    },
  ]
}

export const signOut = async (apolloClient) => {
  try {
    if (!apolloClient) throw new Error('apolloClient instance needs to be passed to signOut function')

    await Promise.all([clearStorage(), apolloClient.clearStore()])

    return Promise.resolve()
  } catch (err) {
    console.warn(err)
  }
}

/**
 *
 * @returns a function that actually performs signout
 */
export const useSignOut = () => {
  const { refreshHealthieToken } = useContext(AppStatesContext)
  const apolloClient = useApolloClient()

  return {
    signOutFn: async () => {
      console.log('Will sign out')
      await signOut(apolloClient)
      await refreshHealthieToken()
      console.log('Did sign out ')
    },
  }
}

/**
 * Cleanup function after fidnout
 */
export const clearStorage = async () => {
  await removeHealthieStorageUser()
}
