/**
 * Get Firebase Program Class by id
 * @typedef {{
 *  durationInWeeks: number,
 *  healthieCourseId: string,
 *  level:number,
 *  startWeek:number,
 *  title:string
 * }} FirebaseProgramClass
 *
 * @param  {Object} firebaseData
 * @param  {String} id
 * @return {FirebaseProgramClass}
 */
export const getFbProgramClassById = (firebaseData, id) => {
  const firebaseProgramClasses = getFbProgramClasses(firebaseData)

  return firebaseProgramClasses.find((fbProgramClass) => fbProgramClass.healthieCourseId === id) || null
}

/**
 * Returns a goal template from Firebase template data by id
 * @param {*} firebaseData
 * @param {*} goalId
 * @returns {*} - found goal object
 */
export const getFbGoalById = (firebaseData, goalId) => {
  return firebaseData['goals'][goalId]
}

/**
 * Parses raw firebase data into an array of classes (templates)
 * @param {*} firebaseData (raw firebase program template data)
 * @returns [FirebaseProgramClass]
 */
export const getFbProgramClasses = (firebaseData) => {
  const firebaseProgramClasses = (firebaseData && firebaseData.programClasses) || {}

  return Object.values(firebaseProgramClasses)
}

/**
 * @param  {Object} firebaseData
 * @param  {String} pillarId
 * @typedef {{ id: String,
 *  name: 'Emotional Health' | 'General' | 'Food' | 'Exercise' | 'Sleep',
 *  programClassIds: Number[]}} Pillar
 * @return {Pillar}
 */
export const getFbPillarById = (firebaseData, pillarId) => {
  const pillar = firebaseData.pillars[pillarId]

  if (!pillar) return null

  const programClassIds = pillar.programClassIds || {}

  return {
    ...pillar,
    id: pillarId,
    programClassIds: Object.keys(programClassIds),
  }
}
