import * as React from 'react'

const HealthV1 = ({ size = 100, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M110.643 70.859c0 5.45-4.419 9.869-9.869 9.869s-9.869-4.419-9.869-9.87c0-5.45 4.419-9.868 9.869-9.868s9.869 4.418 9.869 9.869z"
        fill="#148E86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.291 33.582a8.092 8.092 0 00-8.091 8.092V74.29c0 8.286 6.716 15.003 15.002 15.003h5.968c8.286 0 15.004-6.717 15.004-15.003V42.466a8.883 8.883 0 00-8.884-8.884v-4.399c7.336 0 13.282 5.947 13.282 13.282v31.826c0 10.715-8.686 19.402-19.402 19.402h-5.968c-10.715 0-19.401-8.687-19.401-19.402V41.674c0-6.898 5.592-12.49 12.49-12.49v4.398z"
        fill="#148E86"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M98.575 100.017V78.485h4.398v21.532c0 15.336-12.432 27.769-27.769 27.769-15.336 0-27.768-12.433-27.768-27.769v-9.42h4.398v9.42c0 12.907 10.464 23.37 23.37 23.37 12.908 0 23.37-10.463 23.37-23.37z"
        fill="#148E86"
      />
      <path d="M78.15 26.832h32.766v9.102H78.15v-9.102z" fill="#C7C5D3" />
      <path d="M89.982 47.766V15h9.102v32.766h-9.102z" fill="#C7C5D3" />
    </svg>
  )
}

export default HealthV1
