import { Fragment, useMemo } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Menu, Popover, Transition } from '@headlessui/react'
import { User } from 'react-feather'
import logo from '../../images/logo.png'
import { useSignOut } from '@services/User'

import CONFIG from '@config/Config'

const user = {
  name: 'Provider',
  email: '',
  imageUrl:
    'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header() {
  const { signOutFn } = useSignOut()
  const { userId } = useParams()
  const location = useLocation()

  const userNavigation = useMemo(() => {
    return [{ name: 'Sign out', fn: signOutFn }]
  }, [signOutFn])

  const navigation = useMemo(() => {
    const isAppointmentsActive = location?.pathname?.includes('appointments') ? true : false

    return [
      { name: 'Program', href: `/user/${userId}`, active: !isAppointmentsActive },
      { name: 'Appointments', href: `/user/${userId}/appointments`, active: isAppointmentsActive },
    ]
  }, [userId, location])

  return (
    <header className="bg-white shadow mb-1 ">
      <div className="px-2 sm:px-4 lg:px-8">
        <Popover className="flex justify-between h-16">
          {({ open }) => (
            <>
              <div className="flex px-2 lg:px-0">
                <div className="flex-shrink-0 flex items-center">
                  <img className="h-8 w-auto" src={logo} alt="Calibrate" />
                </div>
                <nav aria-label="Global" className="ml-2 lg:ml-6 flex items-center space-x-4">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={`px-3 py-2 text-gray-900 text-sm font-medium border-b-2 ${
                        item.active ? 'border-secondary' : 'border-gray-100'
                      }`}
                    >
                      {item.name}
                    </a>
                  ))}
                </nav>
              </div>

              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Open main menu</span>
                </Popover.Button>
              </div>
              <Transition.Root show={open} as={Fragment}>
                <div className="lg:hidden">
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Overlay static className="z-20 fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
                  </Transition.Child>

                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      static
                      className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top"
                    >
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                        <div className="pt-3 pb-2">
                          <div className="flex items-center justify-between px-4">
                            <div>
                              <img className="h-8 w-auto" src={logo} alt="Calibrate" />
                            </div>
                            <div className="-mr-2">
                              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                                <span className="sr-only">Close menu</span>
                              </Popover.Button>
                            </div>
                          </div>
                          <div className="mt-3 px-2 space-y-1">
                            {navigation.map((item) => (
                              <a
                                key={item.name}
                                href={item.href}
                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                              >
                                {item.name}
                              </a>
                            ))}
                          </div>
                        </div>
                        <div className="pt-4 pb-2">
                          <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                              <div className="h-10 w-10 rounded-full" />
                            </div>
                            <div className="ml-3">
                              <div className="text-base font-medium text-gray-800">{user.name}</div>
                              <div className="text-sm font-medium text-gray-500">{user.email}</div>
                            </div>
                          </div>
                          <div className="mt-3 px-2 space-y-1">
                            {userNavigation.map((item) => (
                              <div
                                key={item.name}
                                onClick={item.fn}
                                className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                              >
                                {item.name}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition.Child>
                </div>
              </Transition.Root>
              <div className="ml-2 lg:ml-4 flex items-center">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-4 relative flex-shrink-0 z-50">
                  {({ open }) => (
                    <>
                      <div className="flex flex-row items-center">
                        <p className="mr-4 text-gray-500 uppercase font-semibold">{CONFIG.environment}</p>
                        <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                          <span className="sr-only">Open user menu</span>
                          <User className="h-8 w-8 rounded-full border-primary border" />
                          {/* <div className="h-8 w-8 rounded-full bg-primary" /> */}
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <div
                                  onClick={item.fn}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                  )}
                                >
                                  {item.name}
                                </div>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </>
          )}
        </Popover>
      </div>
    </header>
  )
}
