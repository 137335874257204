import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

import { getHealthieStorageUser, signOut } from '@services/User'
import CONFIG from '@config/Config'

const TOKEN_EXPIRED_MESSAGE = 'API Key is Invalid'

const errorLink = onError(({ graphQLErrors, ...x }) => {
  // console.log('Query error:', JSON.stringify(graphQLErrors, null, 2), x)
  if (
    graphQLErrors &&
    graphQLErrors.find((err) => err.message && err.message.toLowerCase().includes(TOKEN_EXPIRED_MESSAGE.toLowerCase()))
  ) {
    // console.log(graphQLErrors)
    signOut(apolloClient)
  }
})

const authLink = setContext(async (_, { headers }) => {
  const user = await getHealthieStorageUser()
  const userToken = user && user.token

  return {
    headers: {
      ...headers,
      AuthorizationSource: userToken ? 'API' : 'WEB',
      Authorization: userToken ? `Basic ${userToken}` : '',
    },
  }
})

const httpLink = new HttpLink({
  uri: CONFIG.healthie.apiUrl,
})

const links = from([authLink, errorLink, httpLink])
const apolloClient = new ApolloClient({
  link: links,
  cache: new InMemoryCache(),
})

export default apolloClient
