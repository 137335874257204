// @ts-nocheck
import React, { useEffect, useMemo, useState, useReducer, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useApolloClient, useLazyQuery } from '@apollo/client'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { Save, PlusCircle } from 'react-feather'
import { Switch } from '@headlessui/react'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  // DragOverlay,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'

import { CSS } from '@dnd-kit/utilities'

import 'react-datepicker/dist/react-datepicker.css'
import {
  useProgramClasses,
  updateUserProgramClasses,
  updateUserGoals,
  useFocusClasses,
  removeDuplicatesFromProgramClass,
  resetUserProgramClasses,
} from '@services/ProgramClasses'
import { useGetUser } from '@services/User'
import APillarsImage from '@atoms/APillarsImage'

import OAddFocusClassModal from '@organisms/OAddFocusClassModal'
import OPageHeader from '@organisms/OPageHeader'
import { deleteUserGoals } from '@services/Goals'
import { GOALS_DATA } from '@constants/Queries'
import { isProduction } from '@utils/helpers'

const ACTIONS = {
  CHANGE_CLASS_DATE: 'CHANGE_CLASS_DATE',
  RESET: 'RESET',
  SET_CLASS: 'SET_CLASS',
  CLEAR_SELECTED_CLASS: 'CLEAR_SELECTED_CLASS',
  SHIFT_CLASS_START_DATE: 'SHIFT_CLASS_START_DATE',
  ASSIGN_CLASS_START_DATE: 'ASSIGN_CLASS_START_DATE',
  ASSIGN_DATES_TO_CLASSES_NOT_IN_HEALTHIE: 'ASSIGN_DATES_TO_CLASSES_NOT_IN_HEALTHIE',
  SHIFT_CLASSES_ON_SORT: 'SHIFT_CLASSES_ON_SORT',
  SAVE: 'SAVE',
  UPDATE_GOAL_DATA: 'UPDATE_GOAL_DATA',
  ALIGN_GOAL_DATES_WITH_CLASS: 'ALIGN_GOAL_DATES_WITH_CLASS',
  ASSIGN_FOCUS_CLASS: 'ASSIGN_FOCUS_CLASS',
  SHOW_ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL: 'SHOW_ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL',
  CLEAR_ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL: 'CLEAR_ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL',
  ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL: 'ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL',
  RESET_START_DATE_FOR_CLASSES: 'RESET_START_DATE_FOR_CLASSES',
}

function areGoalStartDatesAlignedWithClassStartDate(programClassData) {
  let areAligned = true
  const startDate = moment(programClassData.startAt).startOf('day')

  programClassData?.goals.forEach((goal) => {
    if (areAligned) {
      const goalStartOn = moment(goal.start_on).startOf('day')

      if (!startDate.isSame(goalStartOn, 'day')) {
        areAligned = false
      }
    }
  })

  return areAligned
}

function init(initialValues) {
  return {
    additionalFocusClassLevel: null,
    additionalFocusClassSuggestedStartDate: null,
    alreadyUsedClassIdsInLevel: [],
    ...initialValues,
  }
}

function updateEndDates(data, now = moment()) {
  data
    .map((section) => section.data)
    .flat()
    .forEach((programClass, index, programClasses) => {
      const nextProgramClass = programClasses[index + 1]
      let isCurrent = false

      const isSameOrAfter = moment(now).isSameOrAfter(moment(programClass?.startAt))
      const isBefore = moment(now).isBefore(moment(nextProgramClass?.startAt))

      const isStarted = isSameOrAfter

      const isLastAvailableHealthieProgramClass = programClass?.startAt && !nextProgramClass?.startAt

      if (nextProgramClass) {
        isCurrent = (isSameOrAfter && isBefore) || (isSameOrAfter && isLastAvailableHealthieProgramClass)
      } else {
        isCurrent = isSameOrAfter
      }
      programClass.isStarted = isStarted
      programClass.isCurrent = isCurrent
      programClass.endAt = nextProgramClass?.startAt
        ? moment(nextProgramClass?.startAt).startOf('day').subtract(1, 'second')
        : null
    })
}

function sortProgramByStartDates(data) {
  data.forEach((level) => {
    const newData = _.orderBy(
      level.data,
      [
        function (c) {
          if (c.startAt) {
            return moment(c.startAt).toDate()
          } else {
            return moment('2030-12-30').toDate()
          }
        },
      ],
      ['asc']
    )
    level.data = newData
  })
}

function shiftStartDatesFromClassByDays(data, classId, newStartDate, shouldShiftFutureClasses = true) {
  let flatList = data.map((section) => section.data).flat()
  const durationInDays = {}
  flatList.forEach((course) => {
    durationInDays[course.id] = moment(course.endAt).diff(moment(course.startAt), 'days')
  })

  let shouldSort = false
  const firstClassWithNewStartDate = _.find(flatList, ['id', classId])
  if (firstClassWithNewStartDate) {
    const newDate = moment(newStartDate).startOf('day')
    if (moment(firstClassWithNewStartDate.startAt).diff(newDate, 'days') > 0) {
      shouldSort = true
    }
    firstClassWithNewStartDate.startAt = moment(newStartDate).startOf('day')
  }
  if (shouldSort) {
    sortProgramByStartDates(data)
  }

  if (shouldShiftFutureClasses) {
    flatList = data.map((section) => section.data).flat()

    let startingClassIndex = null
    flatList.forEach((programClass, index, programClasses) => {
      if (programClass.id === classId) {
        startingClassIndex = index
      }

      const nextProgramClass = programClasses[index + 1]
      if (
        nextProgramClass &&
        nextProgramClass.startAt &&
        startingClassIndex !== null &&
        index + 1 > startingClassIndex
      ) {
        const newStartAt = moment(programClass.startAt)
          .add(durationInDays[programClass.id] + 1, 'days')
          .startOf('day')
        nextProgramClass.startAt = newStartAt

        // nextProgramClass.goals.forEach((goal) => {
        //   //check if start date  equals newStartAt
        // })

        // const currentStartAt = programClass?.data?.startAt;
        // const currentEndAt = programClass?.data?.endAt;
        // programClass.startAt = currentStartAt
        //   ? moment(currentStartAt).add(howManyDays, 'days').startOf('day')
        //   : null;
        // programClass.endAt = currentEndAt
        //   ? moment(currentEndAt).add(howManyDays, 'days').endOf('day').subtract(1, 'second')
        //   : null;
      }
    })
  }

  updateEndDates(data)

  flatList = data.map((section) => section.data).flat()
}

function assignMissingStartDates(data) {
  data
    .map((section) => section.data)
    .flat()
    .forEach((programClass, index, programClasses) => {
      // Add current date as the beginning of program if the first class is not assigned
      if (index === 0 && !programClass?.startAt) {
        programClass.startAt = moment().startOf('day')
      }

      const nextProgramClass = programClasses[index + 1]
      const currentStartAt = programClass?.startAt
      const nextStartAt = nextProgramClass?.endAt

      if (nextProgramClass && !nextStartAt && currentStartAt) {
        nextProgramClass.startAt = moment(currentStartAt).add(programClass.durationInWeeks, 'weeks').startOf('day')
        programClass.endAt = moment(nextProgramClass.startAt).subtract(1, 'day').endOf('day').subtract(1, 'second')
      }
    })
}

function isThereAClassWithoutAStartDate(data) {
  const flatData = data.map((section) => section.data).flat()

  const foundItems = _.find(flatData, ['startAt', null])
  return foundItems ? true : false
}

function resetClassStartDate(data) {
  data
    .map((section) => section.data)
    .flat()
    .forEach((programClass) => {
      programClass.startAt = null
      programClass.endAt = null
      programClass.isStarted = false
      programClass.isCurrent = false
    })
}

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_CLASS_DATE:
      return state
    case ACTIONS.SET_CLASS:
      return {
        ...state,
        ...action.payload,
      }
    case ACTIONS.CLEAR_SELECTED_CLASS:
      return {
        ...state,
        detailsItem: null,
        detailsItemOriginal: null,
        currentClassPath: null,
      }
    case ACTIONS.SHIFT_CLASS_START_DATE:
      const oldLocalData = _.cloneDeep(state.localData)
      const localDataCopy = [...state.localData]
      const { id, startDate, shouldShift = true } = action.payload
      shiftStartDatesFromClassByDays(localDataCopy, id, startDate, shouldShift)
      // assignGoalsDatesOnClassesChange(state, oldLocalData, localDataCopy)

      // sortProgramByStartDates(localDataCopy);

      return {
        ...state,
        localData: localDataCopy,
      }
    case ACTIONS.ASSIGN_CLASS_START_DATE: {
      //{healthieCourseId: healthieCourseId, level: level, startDate }
      const levelDataPath = `localData.[${action.payload.level - 1}].data`
      const levelData = _.get(state, levelDataPath)
      const programClassIndex = _.findIndex(levelData, ['id', action.payload.healthieCourseId])
      const programClass = _.get(state, `${levelDataPath}.[${programClassIndex}]`)

      const newProgramClass = {
        ...programClass,
      }
      newProgramClass.startAt = moment(action.payload.startDate)
      const newState = { ...state }

      _.set(newState, `${levelDataPath}.[${programClassIndex}]`, newProgramClass)

      const data = _.get(newState, levelDataPath)
      const orderedData = _.orderBy(
        data,
        function (item) {
          return moment(item.startAt).toDate()
        },
        ['asc']
      )
      _.set(newState, levelDataPath, orderedData)
      sortProgramByStartDates(newState.localData)
      updateEndDates(newState.localData)
      // assignGoalsDatesOnClassesChange(newState, state.localData, newState.localData)

      return newState
    }
    case ACTIONS.RESET_START_DATE_FOR_CLASSES: {
      const localDataCopy = [...state.localData]
      // set start date and end date to null
      resetClassStartDate(localDataCopy)

      return {
        ...state,
        localData: localDataCopy,
      }
    }
    case ACTIONS.ASSIGN_DATES_TO_CLASSES_NOT_IN_HEALTHIE: {
      const localDataCopy = [...state.localData]
      assignMissingStartDates(localDataCopy)

      return {
        ...state,
        localData: localDataCopy,
      }
    }
    case ACTIONS.SHIFT_CLASSES_ON_SORT: {
      const { levelIndex, activeId, overId } = action.payload
      const newState = { ...state }
      const localDataCopy = [...newState.localData]

      if (activeId !== overId) {
        const levelDataPath = `[${levelIndex}].data`
        const levelData = _.get(localDataCopy, levelDataPath)

        const oldIndex = _.findIndex(levelData, ['id', activeId])
        const newIndex = _.findIndex(levelData, ['id', overId])

        let previousProgramClass
        let programClassToBeShifted
        let classIdWithNewDate

        if (oldIndex > newIndex) {
          previousProgramClass = levelData[newIndex - 1]
          programClassToBeShifted = levelData[newIndex]
          classIdWithNewDate = activeId
          programClassToBeShifted.startAt = moment(programClassToBeShifted?.startAt).add(1, 'second')
        } else {
          previousProgramClass = levelData[oldIndex - 1]
          programClassToBeShifted = levelData[oldIndex]
          classIdWithNewDate = levelData[oldIndex + 1].id

          let programClassBeforeShifted = levelData[newIndex]
          const currentProgramLength = moment(programClassToBeShifted.endAt).diff(
            moment(programClassToBeShifted.startAt),
            'days'
          )

          programClassToBeShifted.startAt = moment(programClassBeforeShifted?.endAt).add(1, 'day').startOf('day')
          programClassToBeShifted.endAt = moment(programClassToBeShifted?.startAt).add(currentProgramLength, 'day')
        }

        if (previousProgramClass && previousProgramClass.endAt) {
          const newStartAt = moment(previousProgramClass.endAt).add(1, 'day').startOf('day').toDate()

          shiftStartDatesFromClassByDays(localDataCopy, classIdWithNewDate, newStartAt)
        }
      }

      return {
        ...newState,
        localData: localDataCopy,
      }
    }
    case ACTIONS.UPDATE_GOAL_DATA: {
      const { name, question, description, startOn, dueAt, skipEndDate, index } = action.payload
      const newState = { ...state }
      const goal = _.get(newState, `${newState.currentClassPath}.goals.${index}`)

      goal.startOn = moment(startOn).startOf('day')
      goal.start_on = moment(startOn).startOf('day')
      if (skipEndDate) {
        goal.dueAt = null
        goal.due_date = null
      } else {
        goal.dueAt = moment(dueAt).endOf('day')
        goal.due_date = moment(dueAt).endOf('day')
      }
      goal.name = name
      goal.description = description
      goal.question = question
      return { ...newState }
    }
    case ACTIONS.ALIGN_GOAL_DATES_WITH_CLASS: {
      const newState = { ...state }
      const { index } = action.payload

      const goal = _.get(newState, `${newState.currentClassPath}.goals.${index}`)
      const programClass = newState.detailsItem
      const newStartOn = moment(programClass.startAt).startOf('day')
      goal.startOn = newStartOn
      goal.start_on = newStartOn
      const newEndAt = moment(programClass.endAt).endOf('day')
      goal.dueAt = newEndAt
      goal.due_date = newEndAt
      return newState
    }
    case ACTIONS.ASSIGN_FOCUS_CLASS: {
      const { focusClass } = action.payload
      const focusClassCopy = _.cloneDeep(focusClass)
      const { currentClassPath } = state
      const newState = { ...state }
      const currentClass = _.get(newState, currentClassPath)
      console.log('ASSIGN_FOCUS_CLASS', focusClassCopy, currentClassPath, currentClass)
      focusClassCopy.startAt = currentClass.startAt
      focusClassCopy.endAt = currentClass.endAt
      _.set(newState, currentClassPath, focusClassCopy)
      let newDeasignedClasses = []
      if (newState.deassignedClasses?.length) {
        newDeasignedClasses = [...newState.deassignedClasses]
      }
      newDeasignedClasses.push(currentClass)

      return {
        ...newState,
        detailsItem: null,
        detailsItemOriginal: null,
        currentClassPath: null,
        deassignedClasses: newDeasignedClasses,
      }
    }
    case ACTIONS.SHOW_ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL: {
      const { level } = action.payload
      const levelData = state.localData[level - 1]?.data

      const lastClassWithStartDate = _.findLast(levelData, function (el) {
        return el?.endAt
      })

      return {
        ...state,
        additionalFocusClassLevel: level,
        additionalFocusClassSuggestedStartDate: moment(lastClassWithStartDate?.endAt).add(1, 'day'),
        alreadyUsedClassIdsInLevel: levelData.map((cl) => {
          return cl.id
        }),
      }
    }
    case ACTIONS.CLEAR_ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL: {
      return {
        ...state,
        additionalFocusClassLevel: null,
        additionalFocusClassSuggestedStartDate: null,
        alreadyUsedClassIdsInLevel: [],
      }
    }
    case ACTIONS.ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL: {
      const { startDate, focusClass } = action.payload
      const { additionalFocusClassLevel } = state
      const newState = { ...state }
      const localDataCopy = [...newState.localData]
      focusClass.startAt = moment(startDate)
      localDataCopy[additionalFocusClassLevel - 1].data.push(focusClass)
      sortProgramByStartDates(localDataCopy)
      updateEndDates(localDataCopy)
      return {
        ...newState,
        localData: localDataCopy,
        additionalFocusClassLevel: null,
        additionalFocusClassSuggestedStartDate: null,
        alreadyUsedClassIdsInLevel: [],
      }
    }
    case ACTIONS.RESET: {
      console.log('setStateinitial', action.payload)
      return init(action.payload)
    }
    case ACTIONS.SAVE:
      return state
    default:
      throw new Error()
  }
}

function assignGoalsDatesOnClassesChange(state, oldData, newData) {
  const oldDataFlatlist = oldData.map((section) => section.data).flat()
  const modifiedDataFlatList = newData.map((section) => section.data).flat()

  modifiedDataFlatList.forEach((programClass, index) => {
    const oldDataProgramClass = oldDataFlatlist[index]

    programClass?.goals.forEach((goal) => {
      const isSameDay = moment(oldDataProgramClass.startAt).isSame(moment(goal.start_on), 'day')
      if (isSameDay) {
        const newGoalStartAt = programClass.startAt
        const daysDifference = moment(newGoalStartAt).diff(goal.start_on, 'day') || 0
        const newGoalDueAt = moment(goal.dueAt).add(daysDifference, 'day')

        goal.start_on = newGoalStartAt
        goal.due_date = newGoalDueAt
      }
    })
  })
}

function getFormattedDate(date, noDateString = 'No date', includeYear = false) {
  const dateFormat = includeYear ? 'MMM D, YYYY' : 'MMM D'
  return date ? moment(date).format(dateFormat) : noDateString
}

function getFullDateString(startDate, endDate) {
  const startDateString = getFormattedDate(startDate, 'No start date', true)
  const endDateString = getFormattedDate(endDate, '', true)
  return `${startDateString} - ${endDateString}`
}

function getHumanReadableDate(stringDate) {
  return moment(stringDate).format('MMM D')
}

function MClassDetailsOverview({ data }) {
  const fullDateString = getFullDateString(data.startAt, data.endAt)
  return (
    <>
      {/* Description list */}
      <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Start - end</dt>
            <dd className="mt-1 text-sm text-gray-900">{fullDateString} </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Pillar</dt>
            <dd className="mt-1 text-sm text-gray-900">{data.pillar.name}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Assigned in Healthie?</dt>
            <dd className="mt-1 text-sm text-gray-900">{data.notInHealthie ? 'No' : 'Yes'}</dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Focus class</dt>
            <dd className="mt-1 text-sm text-gray-900">No</dd>
          </div>

          {/* <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">About</dt>
              <dd className="mt-1 max-w-prose text-sm text-gray-900">
                <p>
                  Tincidunt quam neque in cursus viverra orci, dapibus nec tristique. Nullam ut sit dolor consectetur
                  urna, dui cras nec sed. Cursus risus congue arcu aenean posuere aliquam.
                </p>
                <p className="mt-5">
                  Et vivamus lorem pulvinar nascetur non. Pulvinar a sed platea rhoncus ac mauris amet. Urna, sem
                  pretium sit pretium urna, senectus vitae. Scelerisque fermentum, cursus felis dui suspendisse velit
                  pharetra. Augue et duis cursus maecenas eget quam lectus. Accumsan vitae nascetur pharetra rhoncus
                  praesent dictum risus suspendisse.
                </p>
              </dd>
            </div> */}
        </dl>
      </div>

      {/* goals */}
      {Boolean(data?.goals?.length) && (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">Goals</h2>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {data.goals?.map((goal) => (
              <div
                key={goal.id}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
              >
                <div className="flex-1 min-w-0">
                  <div className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true"></span>
                    <p className="text-sm text-gray-500 truncate">
                      {getHumanReadableDate(goal.start_on)} - {getHumanReadableDate(goal.due_date)}
                    </p>
                    <p className="text-sm font-medium text-gray-900">{goal.name}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {data?.trackers && (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">Trackers</h2>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {Object.keys(data.trackers).map(
              (
                trackerName,
                // @ts-ignore
                index
              ) => (
                <div
                  key={trackerName}
                  className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
                >
                  <div className="flex-1 min-w-0">
                    <div className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className="text-sm font-medium text-gray-900">{trackerName}</p>
                      <p className="text-sm text-gray-500 truncate">{data.trackers[trackerName]}</p>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}

      {/* Lessons */}
      {!data.notInHealthie && (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">Lessons</h2>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {data.lessons?.map((lesson, index) => (
              <div
                key={`${lesson.id}`}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
              >
                <div className="flex-1 min-w-0">
                  <div className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true"></span>
                    <p className="text-sm font-medium text-gray-900">{lesson.name}</p>
                    {/* <p className="text-sm text-gray-500 truncate">
                      {Boolean(lesson.completed_course_item) ? 'Completed' : 'Not completed'}
                    </p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Guides */}
      {!data.notInHealthie && (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">Guides</h2>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {data.guides?.map((guide) => (
              <div
                key={guide.title}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
              >
                <div className="flex-1 min-w-0">
                  <div className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true"></span>
                    <p className="text-sm font-medium text-gray-900">{guide.title}</p>
                    {/* <a href={guide.url} className="text-sm text-gray-500 underline coursor-pointer truncate">
                      Link
                    </a> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function MClassDetailsDates({ data, dispatch }) {
  const [startDate, setStartDate] = useState(data?.startAt ? moment(data?.startAt).toDate() : new Date())
  const [shouldShiftFutureClasses, setShouldShiftFutureClasses] = useState(true)

  const originalStartDateString = useMemo(() => {
    return getFormattedDate(data.startAt, 'No start date', true)
  }, [data.startAt])

  const originalEndDateString = useMemo(() => {
    return getFormattedDate(data.endAt, 'No end date', true)
  }, [data.endAt])

  const { newEndDateString, diffInDays, diffInDaysString } = useMemo(() => {
    let dateString = 'Update start date'
    let newEndDate = null
    let diffInDays = 0
    let diffInDaysString = '0 days'
    if (startDate) {
      diffInDays = moment(startDate).diff(data.startAt, 'days')
      newEndDate = moment(data.endAt).add(diffInDays, 'days')
      dateString = getFormattedDate(newEndDate, 'Update start date', true)
      diffInDaysString = diffInDays === 1 || diffInDays === -1 ? `${diffInDays} day` : `${diffInDays} days`
    }

    return { dateString, newEndDate, diffInDays, diffInDaysString }
  }, [startDate, data.endAt, data.startAt])

  const standardDurationString = useMemo(() => {
    if (!data.durationInWeeks) {
      return 'Not set'
    }

    return `${data.durationInWeeks * 7} days`
  }, [data.durationInWeeks])

  const classDurationString = useMemo(() => {
    if (!data.startAt || !data.endAt) {
      return 'Not set'
    }
    const duration = moment(data.endAt).diff(moment(data.startAt), 'days') + 1
    return duration === 1 ? `${duration} day` : `${duration} days`
  }, [data.startAt, data.endAt])

  const saveButtonLabel = useMemo(() => {
    if (!data?.startAt) {
      return 'Set start date'
    }

    if (data?.startAt && shouldShiftFutureClasses) {
      return 'Update start & push future dates'
    }
    return 'Update start date'
  }, [data?.startAt, shouldShiftFutureClasses])

  const handleSave = () => {
    if (!shouldShiftFutureClasses || !data.startAt) {
      dispatch({
        type: ACTIONS.ASSIGN_CLASS_START_DATE,
        payload: {
          healthieCourseId: data.id,
          level: data.level,
          startDate,
        },
      })
    } else {
      dispatch({
        type: ACTIONS.SHIFT_CLASS_START_DATE,
        payload: {
          id: data.id,
          level: data.level,
          startDate,
        },
      })
    }
  }

  return (
    <>
      {/* Description list */}
      <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Class duration</dt>
            <dd className="mt-1 text-sm text-gray-900">{classDurationString} </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Standard class duration</dt>
            <dd className="mt-1 text-sm text-gray-900">{standardDurationString} </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Start date</dt>
            <dd className="mt-1 text-sm text-gray-900">{originalStartDateString}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">New start date</dt>
            <dd className="mt-1 text-sm text-gray-900">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                maxDate={new Date('01-01-2030')}
                className="form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </dd>
          </div>

          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">End date</dt>
            <dd className="mt-1 text-sm text-gray-900">{originalEndDateString}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">New end date</dt>
            <dd className="mt-1 text-sm text-gray-900">{newEndDateString}</dd>
          </div>

          {/* <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">About</dt>
              <dd className="mt-1 max-w-prose text-sm text-gray-900">
                <p>
                  Tincidunt quam neque in cursus viverra orci, dapibus nec tristique. Nullam ut sit dolor consectetur
                  urna, dui cras nec sed. Cursus risus congue arcu aenean posuere aliquam.
                </p>
                <p className="mt-5">
                  Et vivamus lorem pulvinar nascetur non. Pulvinar a sed platea rhoncus ac mauris amet. Urna, sem
                  pretium sit pretium urna, senectus vitae. Scelerisque fermentum, cursus felis dui suspendisse velit
                  pharetra. Augue et duis cursus maecenas eget quam lectus. Accumsan vitae nascetur pharetra rhoncus
                  praesent dictum risus suspendisse.
                </p>
              </dd>
            </div> */}
        </dl>
        <div className="pt-12 flex-row flex justify-end">
          {/* {!areGoalStartDatesAlignedWithClassStartDate(data) && (
            <span className="text-right inline-block w-full mb-2 text-sm text-gray-900">
              Please make sure goals dates are correctly assigned
            </span>
          )} */}

          {Boolean(diffInDays) && (
            <Switch.Group as="div" className="flex items-center self-start flex-grow-0">
              <Switch
                checked={shouldShiftFutureClasses}
                onChange={setShouldShiftFutureClasses}
                className={classNames(
                  shouldShiftFutureClasses ? 'bg-secondary' : 'bg-gray-200',
                  'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    shouldShiftFutureClasses ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3 flex flex-col">
                <span className="text-sm font-medium text-gray-900">Push following classes </span>
                <span className="text-sm text-gray-500">Push following start days by {diffInDaysString} </span>
              </Switch.Label>
            </Switch.Group>
          )}

          <div className="flex flex-grow justify-end">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={false}
              onClick={handleSave}
              className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              {saveButtonLabel}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

// @ts-ignore
function MClassGoalEditForm({ goal, onCancel = () => {}, onSave = (arg) => {}, onAlignDates = (arg) => {} }) {
  const { register, handleSubmit, setValue, watch, formState } = useForm()
  const { isDirty } = formState

  const watchedStartOn = watch('startOn', moment(goal?.startOn).toDate() || new Date())
  const watchedDueAt = watch('dueAt', moment(goal?.dueAt).toDate() || null)

  const onSubmit = (data) => {
    onSave({ ...goal, ...data, startOn: watchedStartOn, dueAt: watchedDueAt })
  }
  return (
    <div className="pt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8 bg-gray-50">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Editing Goal</h3>
              <h4 className="text-md leading-6 font-medium text-gray-700">{goal.name}</h4>
              <p className="mt-1 text-sm text-gray-500">
                Keep in mind that saving changes will not send it to Healthie immediately.
              </p>
            </div>
          </div>

          <div className="">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Goal title
                </label>
                <div className="mt-1">
                  <input
                    defaultValue={goal.name}
                    type="text"
                    name="name"
                    id="name"
                    ref={register}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">Used in the app for class progression</p>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="question" className="block text-sm font-medium text-gray-700">
                  Goal daily question
                </label>
                <div className="mt-1">
                  <input
                    defaultValue={goal.question}
                    type="text"
                    name="question"
                    id="question"
                    ref={register}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">Question Members see as today goal tracker in the app </p>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Goal description
                </label>
                <div className="mt-1">
                  <textarea
                    defaultValue={goal.description}
                    id="description"
                    name="description"
                    rows={3}
                    ref={register}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-2">
            <label htmlFor="startOn" className="block text-sm font-medium text-gray-700">
              Start date
            </label>
            <div className="mt-1">
              <DatePicker
                id="startOn"
                defaultValue={moment(goal?.startOn).toDate()}
                // @ts-ignore
                ref={register({
                  name: 'startOn',
                  required: false,
                })}
                selected={watchedStartOn}
                onChange={(date) =>
                  setValue('startOn', date, {
                    shouldDirty: true,
                  })
                }
                className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="sm:col-span-2">
            <label htmlFor="dueAt" className="block text-sm font-medium text-gray-700">
              End date
            </label>
            <div className="mt-1">
              <DatePicker
                id="dueAt"
                defaultValue={moment(goal?.dueAt).toDate()}
                // @ts-ignore
                ref={register({
                  name: 'dueAt',
                  required: false,
                })}
                selected={watchedDueAt}
                onChange={(date) =>
                  setValue('dueAt', date, {
                    shouldDirty: true,
                  })
                }
                className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          {/* <div className="sm:col-span-2 flex items-end justify-center">
            <div
              onClick={() => {
                onAlignDates({ index: goal.index })
              }}
              className="cursor-pointer bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Align with class
            </div>
          </div> */}
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onCancel}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!isDirty}
              className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              {isDirty ? 'Save goal updates' : 'No changes'}
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

// @ts-ignore
function MClassGoalAddForm({ goal, onCancel = () => {}, onSave = (arg) => {} }) {
  const { register, handleSubmit, setValue, watch, formState } = useForm()
  const { isDirty } = formState

  const watchedStartOn = watch('startOn', goal?.startOn ? moment(goal?.startOn).toDate() : new Date())
  const watchedDueAt = watch('dueAt', goal?.dueAt ? moment(goal?.dueAt).toDate() : new Date())
  const onSubmit = (data) => {
    onSave({ ...goal, ...data, startOn: watchedStartOn, dueAt: watchedDueAt })
  }
  return (
    <div className="pt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8 bg-gray-50">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8 divide-y divide-gray-200">
        <div className="space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">Adding new goal</h3>
            </div>
          </div>

          <div className="">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Goal title
                </label>
                <div className="mt-1">
                  <input
                    defaultValue={goal.name}
                    type="text"
                    name="name"
                    id="name"
                    ref={register}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">Used in the app for class progression</p>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="question" className="block text-sm font-medium text-gray-700">
                  Goal daily question
                </label>
                <div className="mt-1">
                  <input
                    defaultValue={goal.question}
                    type="text"
                    name="question"
                    id="question"
                    ref={register}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <p className="mt-2 text-sm text-gray-500">Question Members see as today goal tracker in the app </p>
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                  Goal description
                </label>
                <div className="mt-1">
                  <textarea
                    defaultValue={goal.description}
                    id="description"
                    name="description"
                    rows={3}
                    ref={register}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label htmlFor="startOn" className="block text-sm font-medium text-gray-700">
              Start date
            </label>
            <div className="mt-1">
              <DatePicker
                id="startOn"
                defaultValue={watchedStartOn}
                // @ts-ignore
                ref={register({
                  name: 'startOn',
                  required: true,
                })}
                selected={watchedStartOn}
                onChange={(date) =>
                  setValue('startOn', date, {
                    shouldDirty: true,
                  })
                }
                className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
          <div className="sm:col-span-3">
            <label htmlFor="dueAt" className="block text-sm font-medium text-gray-700">
              End date
            </label>
            <div className="mt-1">
              <DatePicker
                id="dueAt"
                defaultValue={watchedDueAt}
                // @ts-ignore
                ref={register({
                  name: 'dueAt',
                  required: true,
                })}
                selected={watchedDueAt}
                onChange={(date) =>
                  setValue('dueAt', date, {
                    shouldDirty: true,
                  })
                }
                className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              />
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onCancel}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
            >
              Save new goal
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

// @ts-ignore
function MClassDetailsGoals({ originalData, data, onGoalSave = (goal) => {} }) {
  const [goalInEditMode, setGoalInEditMode] = useState(null)
  const [goalInAddMode, setGoalInAddMode] = useState(null)

  const handleGoalEdit = (goal) => {
    console.log('Goal edit', goal)
    handleCancelGoalAdd()
    setGoalInEditMode({ ...goal, courseId: data.id })
  }

  const handleGoalAdd = (goalTemplate) => {
    const cleanGoal = {
      name: goalTemplate.goal,
      question: goalTemplate.goalPrompt,
      description: goalTemplate.description,
      startOn: data.startAt,
      dueAt: data.endAt,
      courseId: data.id,
    }
    handleCancelGoalEdit()
    setGoalInAddMode(cleanGoal)
  }

  const handleCancelGoalAdd = () => {
    setGoalInAddMode(null)
  }

  const handleCancelGoalEdit = () => {
    setGoalInEditMode(null)
  }

  const handleSaveUpdates = async (updtedGoalData) => {
    await onGoalSave(updtedGoalData)
    // dispatch({ type: ACTIONS.UPDATE_GOAL_DATA, payload: { ...updtedGoalData } })
    // handleCancelGoalEdit()
  }

  const handleAlignDatesWithClass = (params) => {
    // dispatch({ type: ACTIONS.ALIGN_GOAL_DATES_WITH_CLASS, payload: params })
  }

  const handleSaveNewGoal = async (newGoalData) => {
    await onGoalSave(newGoalData)
    // handleCancelGoalAdd()
  }

  return (
    <>
      {goalInEditMode && (
        <MClassGoalEditForm
          goal={goalInEditMode}
          onSave={handleSaveUpdates}
          onCancel={handleCancelGoalEdit}
          onAlignDates={handleAlignDatesWithClass}
        />
      )}

      {goalInAddMode && (
        <MClassGoalAddForm goal={goalInAddMode} onSave={handleSaveNewGoal} onCancel={handleCancelGoalAdd} />
      )}

      {data?.goals?.length ? (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">Goals</h2>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {data.goals?.map(
              (
                goal,
                // @ts-ignore
                index
              ) => (
                <div
                  key={goal.id}
                  className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
                >
                  <div
                    className="flex-1 min-w-0"
                    onClick={() => {
                      handleGoalEdit({ ...goal, index: index })
                    }}
                  >
                    <div className="focus:outline-none cursor-pointer">
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className="text-sm text-gray-500 truncate">
                        {getHumanReadableDate(goal.start_on)} - {getHumanReadableDate(goal.due_date)}
                      </p>
                      <p className="text-sm font-medium text-gray-900">{goal.name}</p>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      ) : (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">No goals</h2>
        </div>
      )}

      {data?.suggestedGoals?.length ? (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">Class goals templates</h2>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {data.suggestedGoals?.map((goal) => (
              <div
                onClick={() => {
                  handleGoalAdd(goal)
                }}
                key={`${goal.name}`}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
              >
                <div className="flex-1 min-w-0">
                  <div className="focus:outline-none cursor-pointer">
                    <span className="absolute inset-0" aria-hidden="true"></span>
                    <p className="text-sm font-medium text-gray-900">{goal.goal}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">No suggested goals</h2>
        </div>
      )}
      <div className="mt-0 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
        {/* <h2 className="text-sm font-medium text-gray-500">Add goal</h2> */}
        <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
          <div
            onClick={() => {
              handleGoalAdd({})
            }}
            className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500"
          >
            <div className="flex-1 min-w-0">
              <div className="focus:outline-none cursor-pointer">
                <span className="absolute inset-0" aria-hidden="true"></span>
                <p className="text-sm font-medium text-gray-900">Add custom goal</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function MClassDetailsFocusClasses({ programClass, dispatch, alreadyAssignedClassesIds }) {
  const [selectedFocusClass, setSelectedFocusClass] = useState(null)
  const focusClasses = useFocusClasses(programClass.level, alreadyAssignedClassesIds)

  const handleAssignFocusClass = () => {
    dispatch({
      type: ACTIONS.ASSIGN_FOCUS_CLASS,
      payload: { healthieCourseIdToReplace: programClass.id, focusClass: selectedFocusClass },
    })
  }

  const isTheSameClass = useCallback(
    (focusClass) => {
      return programClass.id === focusClass.id
    },
    [programClass]
  )
  return (
    <>
      {focusClasses?.length ? (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">Focus classes</h2>
          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
            {focusClasses?.map((focusClass, index) => (
              <div
                key={focusClass.title}
                className={`${
                  selectedFocusClass?.id === focusClass.id ? 'border-green-300' : 'border-gray-300'
                } relative rounded-lg border  bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-green-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500`}
                onClick={() => {
                  !isTheSameClass(focusClass) && setSelectedFocusClass(focusClass)
                }}
              >
                <div className="flex-1 min-w-0">
                  <div className="focus:outline-none cursor-pointer">
                    <span className="absolute inset-0" aria-hidden="true"></span>
                    {/* <p className="text-sm text-gray-500 truncate">{focusClass.pillar.name}</p> */}
                    <p className="text-sm font-medium text-gray-900">{focusClass.title}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {selectedFocusClass && (
            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => {
                    setSelectedFocusClass(null)
                  }}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Reset
                </button>
                <button
                  type="submit"
                  disabled={false}
                  onClick={handleAssignFocusClass}
                  className={`ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-secondary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  Assign focus class
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
          <h2 className="text-sm font-medium text-gray-500">No focus classes</h2>
        </div>
      )}
    </>
  )
}

function OClassDetails({
  course,
  courseOriginal = { data: {} },
  onClose,
  dispatch,
  onGoalSave = (goalData) => {},
  alreadyAssignedClassesIds = [],
}) {
  const [whichTab, setWhichTab] = useState('overview')

  const { data: originalData } = courseOriginal
  const isFocusClassCapable = useMemo(() => {
    return course.isFocusClass || course.isFocusClassPlaceholder
  }, [course])

  const tabActiveClassName =
    'border-secondary cursor-pointer  text-gray-900 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
  const tabInactiveClassName =
    'cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'

  return (
    <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none order-last" tabIndex={0}>
      <article>
        {/* Profile header */}
        <div>
          <div>
            <div className="h-24 flex items-center w-full object-cover bg-primary mx-auto px-4 sm:px-6 lg:px-8">
              <APillarsImage size={74} imageSet={1} name={course.pillar.name} />
              <h2 className="text-gray-50 text-2xl ml-6 font-bold">{course.title}</h2>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="mt-6 sm:mt-2 2xl:mt-5">
          <div className="border-b border-gray-200">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-row justify-between">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {/* Current: "border-pink-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
                <div
                  onClick={() => {
                    setWhichTab('overview')
                  }}
                  className={whichTab === 'overview' ? tabActiveClassName : tabInactiveClassName}
                  aria-current="page"
                >
                  Overview
                </div>
                <div
                  onClick={() => {
                    setWhichTab('dates')
                  }}
                  className={whichTab === 'dates' ? tabActiveClassName : tabInactiveClassName}
                >
                  Dates
                </div>
                <div
                  onClick={() => {
                    setWhichTab('goals')
                  }}
                  className={whichTab === 'goals' ? tabActiveClassName : tabInactiveClassName}
                >
                  Goals
                </div>
                {isFocusClassCapable && (
                  <div
                    onClick={() => {
                      setWhichTab('focusclass')
                    }}
                    className={whichTab === 'focusclass' ? tabActiveClassName : tabInactiveClassName}
                  >
                    Focus class
                  </div>
                )}
              </nav>

              <div className="mt-1">
                <button
                  type="button"
                  onClick={onClose}
                  className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                >
                  {/* Heroicon name: solid/phone */}
                  <svg
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  <span>Close</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {whichTab === 'overview' && <MClassDetailsOverview data={course} dispatch={dispatch} />}
        {whichTab === 'dates' && <MClassDetailsDates data={course} originalData={originalData} dispatch={dispatch} />}
        {whichTab === 'goals' && (
          <MClassDetailsGoals data={course} originalData={originalData} dispatch={dispatch} onGoalSave={onGoalSave} />
        )}
        {whichTab === 'focusclass' && (
          <MClassDetailsFocusClasses
            programClass={course}
            originalData={originalData}
            dispatch={dispatch}
            alreadyAssignedClassesIds={alreadyAssignedClassesIds}
          />
        )}
      </article>
    </main>
  )
}

function MClassListItem({ course, onClick, isSelected, id, ...rest }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
    transition: {
      duration: 150, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    // transition,
  }

  const startDateString = course.startAt ? moment(course.startAt).format('MMM D, YYYY') : 'No start date'
  const endDateString = course.endAt ? moment(course.endAt).startOf('day').format('MMM D, YYYY') : ''
  let howManyDaysString = null
  if (course.startAt && course.endAt) {
    const howManyDays = moment(course.endAt).diff(moment(course.startAt), 'days') + 1

    if (howManyDays === 0 || howManyDays > 1) {
      howManyDaysString = `${howManyDays} days`
    } else {
      howManyDaysString = `${howManyDays} day`
    }
  }

  let fullDateString = `${startDateString} - ${endDateString}`
  if (howManyDaysString) {
    fullDateString = fullDateString + ` (${howManyDaysString})`
  }

  let howManyGoalsString = 'No goals'
  if (course.goals?.length === 1) {
    howManyGoalsString = '1 goal'
  } else if (course.goals?.length > 1) {
    howManyGoalsString = `${course.goals?.length} goals`
  }

  let additionalString
  if (course.isFocusClass) {
    additionalString = '(Focus class)'
  } else if (course.isFocusClassPlaceholder) {
    additionalString = '(Focus class placeholder)'
  }

  return (
    <li
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className={`relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500 ${
        isSelected && 'bg-gray-100'
      }`}
    >
      <div className="flex-shrink-0 mr-2">
        <APillarsImage name={course.pillar.name} imageSet={2} size={36} />
      </div>
      <div className="flex-1 min-w-0">
        <div onClick={onClick} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true"></span>
          {course.isCurrent && <p className="text-sm text-secondary truncate">Current class</p>}
          <p className="text-sm text-gray-700 truncate">{fullDateString}</p>
          <p className={`text-sm font-medium ${course.startAt ? 'text-gray-900' : 'text-gray-500'}`}>
            {course.title} {additionalString}
          </p>
          <p className={`text-sm truncate text-gray-500`}>{howManyGoalsString}</p>
        </div>
      </div>
    </li>
  )
}

export default function PProgramManagement() {
  const { userId } = useParams()
  const [state, dispatch] = useReducer(reducer, {}, init)
  const [activeId, setActiveId] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [isRemovingDuplicates, setIsRemovingDuplicates] = useState(false)
  const apolloClient = useApolloClient()

  const { localData, detailsItem, detailsItemOriginal } = state
  const { data: sectionedProgramClasses, loading, error } = useProgramClasses(userId)
  const sectionProgramClassesObject = sectionedProgramClasses || {}
  const { data: userData } = useGetUser(userId)
  const [isReset, setIsReset] = useState(false)

  const [getUserGoals, { data: userGoals }] = useLazyQuery(GOALS_DATA, {
    variables: {
      user_id: userId,
      start_range: moment('2016-01-01').format('YYYY-MM-DD'),
      end_range: moment().format('YYYY-MM-DD'),
      per_page: 400,
      date_range_is_selected: true,
      status_filter: 'all',
      frequency_filter: 'all',
    },
  })

  useEffect(() => {
    if (!isProduction) {
      getUserGoals()
    }
  }, [getUserGoals])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  const { fullName, dob } = useMemo(() => {
    let fullName = 'Loading...'
    let dob = null
    if (userData?.user) {
      const dobInputString = userData?.user?.dob
      fullName = userData?.user?.full_legal_name
      if (dobInputString) {
        dob = moment(dobInputString).format('MM / DD / YYYY')
      }
    }
    return { fullName, dob }
  }, [userData])

  const duplicates = useMemo(() => {
    const idsMap = localData?.reduce((acc, item) => {
      item.data?.forEach((cls) => {
        const { id } = cls
        if (acc[id]) {
          acc[id].push(cls)
        } else {
          acc[id] = [cls]
        }
      })
      return acc
    }, {})

    return Object.keys(idsMap || {}).reduce((acc, id) => {
      if (idsMap[id].length > 1) {
        acc[id] = idsMap[id]
      }
      return acc
    }, {})
  }, [localData])

  const hasDuplicates = useMemo(() => !!Object.keys(duplicates).length, [duplicates])

  const idsOfAlreadyAssignedClassesToFilterOutForSelectedClass = useMemo(() => {
    let dataToReturn = []
    if (detailsItem && localData) {
      const levelIndex = parseInt(detailsItem.level, 10) - 1
      const currentLevelData = localData[levelIndex].data
      dataToReturn = currentLevelData.map((cl) => {
        return cl.id
      })
    }

    return dataToReturn
  }, [detailsItem, localData])

  const dispatchNewData = useCallback(() => {
    const newData = _.cloneDeep(sectionedProgramClasses)

    // sortProgramByStartDates(newData)
    // updateEndDates(newData)
    // @ts-ignore
    dispatch({
      type: ACTIONS.RESET,
      payload: { localData: newData },
    })
  }, [sectionedProgramClasses])

  useEffect(() => {
    if (!localData && sectionedProgramClasses && !loading) {
      dispatchNewData()
    }
  }, [sectionedProgramClasses, localData, dispatchNewData, loading])

  useDeepCompareEffect(() => {
    if (sectionProgramClassesObject) {
      dispatchNewData()
    }
  }, [sectionProgramClassesObject])

  const anyMissingDates = useMemo(() => {
    if (localData) {
      return isThereAClassWithoutAStartDate(localData)
    }
    return false
  }, [localData])

  const anyChanges = useMemo(() => {
    if (!localData || !sectionedProgramClasses) return false

    return !_.isEqual(localData, sectionedProgramClasses)
  }, [localData, sectionedProgramClasses])

  const idsForSorting = useMemo(() => {
    let structure = {}
    if (localData) {
      localData.forEach((level, levelIndex) => {
        structure[levelIndex] = level.data.map((course) => {
          return `${levelIndex}/${course.id}`
        })
      })
    }
    return structure
  }, [localData])

  function handleDragStart(event) {
    const { active } = event

    setActiveId(active.id)
  }

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      const [levelIndex, activeId] = active.id.split('/')
      const [, overId] = over.id.split('/')
      dispatch({
        type: ACTIONS.SHIFT_CLASSES_ON_SORT,
        payload: {
          levelIndex,
          activeId,
          overId,
        },
      })
    }
    setActiveId(null)
  }

  function handleAddFocusClass({ startDate, focusClass }) {
    dispatch({ type: ACTIONS.ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL, payload: { startDate, focusClass } })
  }

  const handleSaveChanges = useCallback(async () => {
    setIsSaving(true)
    if (isReset) {
      // remove class start/end dates, remove goals, remove lesson completion
      await deleteUserGoals(userGoals, apolloClient)
      await resetUserProgramClasses({
        user: userData.user,
        programClasses: localData.map((item) => item.data).flat(),
        apolloClient,
      })
      setIsReset(false)
    } else {
      await updateUserProgramClasses({
        user: userData.user,
        programClasses: localData.map((item) => item.data).flat(),
        originalProgramClasses: sectionedProgramClasses.map((item) => item.data).flat(),
        deassignedClasses: state.deassignedClasses,
        apolloClient,
      })
    }

    dispatch({
      type: ACTIONS.SAVE,
    })

    await apolloClient.reFetchObservableQueries()
    setIsSaving(false)
  }, [apolloClient, localData, sectionedProgramClasses, state, userData.user, userGoals, isReset])

  const handleRemoveDuplicates = useCallback(async () => {
    // TODO: Replace with custom modal
    // eslint-disable-next-line no-restricted-globals
    const isConfirm = await confirm('Removing duplicates will affect user in healthie. Continue?')
    if (!isConfirm) return
    setIsRemovingDuplicates(true)
    await removeDuplicatesFromProgramClass({
      duplicates,
      apolloClient,
    })
    await apolloClient.reFetchObservableQueries()

    dispatch({
      type: ACTIONS.RESET,
      payload: { localData: [] },
    })

    setIsRemovingDuplicates(false)
  }, [apolloClient, duplicates])

  const handleGoalSave = useCallback(
    async (goalData) => {
      await updateUserGoals({
        goals: [goalData],
        user: userData.user,
        apolloClient,
      })

      await apolloClient.reFetchObservableQueries()
    },
    [apolloClient, userData.user]
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Oh no... {error}</p>
  return (
    <>
      <div className="h-screen overflow-hidden bg-white">
        <div className="flex flex-col h-screen overflow-hidden">
          <OPageHeader />
          <div className="flex min-h-0 flex-col min-w-0 flex-1">
            <div className="flex-auto relative z-0 flex overflow-hidden">
              {detailsItem && (
                <OClassDetails
                  key={detailsItem.id}
                  course={detailsItem}
                  courseOriginal={detailsItemOriginal}
                  alreadyAssignedClassesIds={idsOfAlreadyAssignedClassesToFilterOutForSelectedClass}
                  onClose={() => {
                    // @ts-ignore
                    dispatch({
                      type: ACTIONS.CLEAR_SELECTED_CLASS,
                    })
                  }}
                  onGoalSave={handleGoalSave}
                  dispatch={dispatch}
                />
              )}
              <aside className="order-first flex flex-col flex-shrink-0 w-2/5 border-r border-gray-200 relative">
                <div className="px-6 pt-6 pb-4 flex justify-between items-center">
                  <div>
                    <h2 className="text-lg font-medium text-gray-900">{fullName}</h2>
                    <p className="text-base text-gray-600">{dob ? dob : 'Missing DOB'}</p>
                  </div>

                  <div className="flex flex-col">
                    {hasDuplicates && (
                      <div className="mb-2">
                        <p className="text-base text-bold text-center text-red-500">
                          {'Member has duplicate Classes in program!'}
                        </p>
                      </div>
                    )}
                    <div className="flex justify-end">
                      {hasDuplicates && (
                        <button
                          type="button"
                          disabled={isRemovingDuplicates}
                          onClick={handleRemoveDuplicates}
                          className="cursor-pointer bg-white py-2 px-3 mr-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <span>{isRemovingDuplicates ? 'Removing...' : 'Remove duplicates'}</span>
                        </button>
                      )}

                      {anyMissingDates && (
                        <div
                          onClick={() => {
                            dispatch({
                              type: ACTIONS.ASSIGN_DATES_TO_CLASSES_NOT_IN_HEALTHIE,
                            })
                          }}
                          className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Assign missing dates
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!isProduction && (
                  <div className="px-6 py-4">
                    <div
                      className="inline-block cursor-pointer bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setIsReset(true)
                        dispatch({
                          type: ACTIONS.RESET_START_DATE_FOR_CLASSES,
                        })
                      }}
                    >
                      Reset dates and goals
                    </div>
                  </div>
                )}
                {/* Directory list */}
                <nav className="flex-1 min-h-0 overflow-y-auto relative" aria-label="Program">
                  {
                    // @ts-ignore
                    localData?.map((level, levelIndex) => (
                      <div key={level.title}>
                        <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-primary px-6 py-1  text-gray-50 flex flex-row justify-between">
                          <h3 className="text-xl font-regular uppercase">{level.title}</h3>
                          {levelIndex > 0 && (
                            <div
                              className="flex flex-row items-start underline cursor-pointer"
                              onClick={() => {
                                dispatch({
                                  type: ACTIONS.SHOW_ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL,
                                  payload: {
                                    level: levelIndex + 1,
                                  },
                                })
                              }}
                            >
                              <PlusCircle className="mr-2" icon="plus-circle" />
                              Focus Class
                            </div>
                          )}
                        </div>
                        <ul className="relative z-0 divide-y divide-gray-200">
                          <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                          >
                            <SortableContext items={idsForSorting[levelIndex]} strategy={verticalListSortingStrategy}>
                              {level.data.map((course, index) => (
                                <MClassListItem
                                  key={`${levelIndex}/${course.id}`}
                                  id={`${levelIndex}/${course.id}`}
                                  course={course}
                                  isSelected={detailsItem ? Boolean(course?.id === detailsItem?.id) : false}
                                  onClick={() => {
                                    // @ts-ignore
                                    dispatch({
                                      type: ACTIONS.SET_CLASS,
                                      payload: {
                                        detailsItem: course,
                                        detailsItemOriginal: sectionedProgramClasses[levelIndex]['data'][index],
                                        currentClassPath: `localData.${levelIndex}.data.${index}`,
                                      },
                                    })
                                  }}
                                />
                              ))}
                            </SortableContext>
                            {/* <DragOverlay>{activeId ? <div id={activeId}>activeId</div> : null}</DragOverlay> */}
                          </DndContext>
                        </ul>
                      </div>
                    ))
                  }
                </nav>

                {((anyChanges || isReset) && !isRemovingDuplicates)  && (
                  <button
                    type="button"
                    disabled={isSaving}
                    onClick={handleSaveChanges}
                    className={`z-50 absolute bottom-6 right-6 flex-row items-center inline-flex justify-center py-4 px-4 border border-transparent text-sm font-medium text-white bg-secondary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-full`}
                  >
                    <Save />
                    <span className="ml-2">{isSaving ? 'Saving...' : 'Save changes'}</span>
                  </button>
                )}
              </aside>
            </div>
          </div>
        </div>
      </div>
      <OAddFocusClassModal
        shouldOpen={Boolean(state.additionalFocusClassLevel)}
        level={state.additionalFocusClassLevel}
        suggestedStartDate={state.additionalFocusClassSuggestedStartDate}
        idsOfalreadyAssignedClassesToFilterOut={state.alreadyUsedClassIdsInLevel}
        onCancel={() => {
          dispatch({ type: ACTIONS.CLEAR_ADD_ADDITIONAL_FOCUS_CLASS_FOR_LEVEL })
        }}
        onConfirm={handleAddFocusClass}
      />
    </>
  )
}
