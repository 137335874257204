import * as React from 'react'

const SleepV1 = ({ size = 100, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M89 18.553a6.553 6.553 0 11-13.106 0 6.553 6.553 0 0113.107 0zM115.214 31.66a6.553 6.553 0 11-13.107 0 6.553 6.553 0 0113.107 0zM128.32 57.873a6.553 6.553 0 11-13.106 0 6.553 6.553 0 0113.106 0z"
        fill="#FAB52F"
      />
      <path
        d="M124.194 76.798a56.22 56.22 0 01-68.212 49.696 56.22 56.22 0 01-42.634-42.635 56.216 56.216 0 0149.695-68.212 43.724 43.724 0 0061.151 61.15z"
        fill="#9F8EE6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.96 14.567a2.2 2.2 0 01-.148 2.388 41.524 41.524 0 0058.074 58.074A2.2 2.2 0 01126.385 77a58.416 58.416 0 11-63.544-63.544 2.2 2.2 0 012.119 1.11zm-6.414 3.914A54.018 54.018 0 0030.02 109.82a54.02 54.02 0 0080.93-5.155 54.028 54.028 0 0010.409-23.37 45.924 45.924 0 01-62.814-62.814z"
        fill="#9F8EE6"
      />
    </svg>
  )
}

export default SleepV1
