import * as React from 'react'

const JournalSet2 = ({ size = 30, color = '#9F8EE6', ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill="#fff" d="M0 0h30v30H0z" />
      <path d="M7 6.429a2.857 2.857 0 002.857 2.857h12.857V25H9.857A2.857 2.857 0 017 22.143V6.429z" fill={color} />
      <path d="M8.429 6.429a1.429 1.429 0 001.428 1.428h11.429V5H9.857A1.429 1.429 0 008.43 6.429z" fill={color} />
    </svg>
  )
}

export default JournalSet2
