import * as React from 'react'

const HealthV2 = ({ size = 30, color = '#9F8EE6', ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 0h30v30H0V0z" />
      <circle cx={20.216} cy={15.286} r={1.817} fill={color} />
      <path
        d="M8.895 8.017v0A1.895 1.895 0 007 9.912v5.456a3.717 3.717 0 003.717 3.717v0a3.717 3.717 0 003.717-3.717v-5.31a2.04 2.04 0 00-2.04-2.041v0"
        stroke={color}
        strokeWidth={1.5}
      />
      <path
        d="M10.8 18.92v1.735c0 2.6 2.108 4.708 4.708 4.708v0c2.6 0 4.708-2.108 4.708-4.708V16.69"
        stroke={color}
        strokeWidth={1.5}
      />
      <path fill={color} d="M16.05 7.179h6.033v1.676H16.05z" />
      <path fill={color} d="M18.229 11.033V5h1.676v6.033z" />
    </svg>
  )
}

export default HealthV2
