import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import { useFocusClasses } from '@services/ProgramClasses'

export default function OAddFocusClassModal({
  level = 2,
  shouldOpen = false,
  suggestedStartDate,
  idsOfalreadyAssignedClassesToFilterOut = [],
  onCancel = () => {},
  onConfirm = ({ startDate, focusClass }) => {},
}) {
  const cancelButtonRef = useRef()
  const focusClasses = useFocusClasses(level, idsOfalreadyAssignedClassesToFilterOut)
  const [selectedFocusClass, setSelectedFocusClass] = useState()
  const [startDate, setStartDate] = useState(suggestedStartDate ? moment(suggestedStartDate).toDate() : new Date())

  useEffect(() => {
    const date = suggestedStartDate ? moment(suggestedStartDate).toDate() : new Date()
    setStartDate(date)
  }, [suggestedStartDate])
  return (
    <Transition.Root show={shouldOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={shouldOpen}
        onClose={onCancel}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Add additional Focus Class for Level {level}
                  </Dialog.Title>
                  <div className="mt-2">
                    <>
                      {focusClasses?.length ? (
                        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
                          <h2 className="text-sm font-medium text-gray-500">Focus classes</h2>
                          <div className="mt-1 grid grid-cols-1 gap-4 sm:grid-cols-2">
                            {focusClasses?.map((focusClass, index) => (
                              <div
                                key={focusClass.id}
                                className={`${
                                  selectedFocusClass?.id === focusClass.id ? 'border-green-300' : 'border-gray-300'
                                } relative rounded-lg border  bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-green-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-pink-500`}
                                onClick={() => {
                                  setSelectedFocusClass(focusClass)
                                }}
                              >
                                <div className="flex-1 min-w-0">
                                  <div className="focus:outline-none cursor-pointer">
                                    <span className="absolute inset-0" aria-hidden="true"></span>
                                    {/* <p className="text-sm text-gray-500 truncate">{focusClass.pillar.name}</p> */}
                                    <p className="text-sm font-medium text-gray-900">{focusClass.title}</p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        <div className="mt-8 max-w-5xl mx-auto px-4 pb-12 sm:px-6 lg:px-8">
                          <h2 className="text-sm font-medium text-gray-500">No focus classes</h2>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
              <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Start date</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      />
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  disabled={!selectedFocusClass}
                  className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm ${
                    selectedFocusClass ? 'bg-secondary hover:bg-primary' : 'bg-gray-600'
                  }`}
                  onClick={() => {
                    onConfirm({ startDate, focusClass: selectedFocusClass })
                  }}
                >
                  Add Focus Class
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={onCancel}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
