import { useContext, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { useQuery, gql } from '@apollo/client'
import { COURSE_MEMBERSHIPS } from '@constants/Queries'

import moment from 'moment'
import groupBy from 'lodash/groupBy'
import { useFirebaseData } from '@services/FirebaseDataSync'
import { AppStatesContext } from '@services/AppStates'
import { getFormattedHealthieDate, getHealthieDateFormat, getCurrentDate } from '@utils/dateTime'
import { getFbProgramClassById, getFbPillarById, getFbProgramClasses, getFbGoalById } from '@utils/programClasses'

import { useGoalsData } from '@services/Goals'

const useProgramClassesQuery = (healthieUserId) => {
  const {
    data = {},
    loading,
    error,
  } = useQuery(COURSE_MEMBERSHIPS, {
    variables: { client_id: healthieUserId },
  })

  return { data, loading, error }
}

/**
 *
 * @param {*} programClasses - the structure that includes data from Healthie and Firebase combined
 * @param {*} goalsData - Goals data from Healthie
 * @returns
 */
function enrichProgramClassesWithGoals(programClasses, goalsData) {
  const programClasesCopy = _.cloneDeep(programClasses)
  goalsData.forEach((goal) => {
    // console.log("-Goal level", goal.courseId, goal.name)
    programClasesCopy.forEach((level) => {
      // console.log("--Level level", goal.courseId, level.title)
      level.data.forEach((course) => {
        if (!course.goals) {
          course.goals = []
        }

        if (course.id === goal.courseId) {
          // console.log('---Course level - will add', goal.courseId, level.title, course.data.id, goal.id);
          course?.goals.push(goal)
        }
      })
    })
  })
  return programClasesCopy
}
/**
 *
 * @param {*} healthieUserId
 */
const useProgramClasses = (healthieUserId) => {
  const [sectionedProgramClasses, setSectionedProgramClasses] = useState()

  const { dateNow, updateValue, programStartDate, programEndDate } = useContext(AppStatesContext)

  const [firebaseData, firebaseLoading, firebaseError] = useFirebaseData()

  const { data: queryData, loading: queryLoading, error: queryError } = useProgramClassesQuery(healthieUserId)
  const { data: goalsData } = useGoalsData({
    healthieUserId,
    start_range: programStartDate,
    end_range: programEndDate,
  })

  const loading = queryLoading || firebaseLoading
  const error = queryError || firebaseError

  useEffect(() => {
    if (queryData && queryData.courseMemberships && firebaseData) {
      const sectionedProgramClasses = getSectionedProgramClasses(queryData.courseMemberships, firebaseData, {
        // @ts-ignore
        dateNow,
      })
      // @ts-ignore
      setSectionedProgramClasses(sectionedProgramClasses)
    }
  }, [queryData, firebaseData, dateNow, updateValue])

  const sectionedProgramClassesWithGoals = useMemo(() => {
    if (sectionedProgramClasses && goalsData) {
      return enrichProgramClassesWithGoals(sectionedProgramClasses, goalsData)
    } else {
      return sectionedProgramClasses
    }
  }, [sectionedProgramClasses, goalsData])

  return { data: sectionedProgramClassesWithGoals, loading, error }
}

/**
 * Shorthand Hook that returns focus classes
 * @returns [] - Array of focus classes
 */
export const useFocusClasses = (level = null, idsOfalreadyAssignedClassesToFilterOut = []) => {
  const [firebaseData] = useFirebaseData()
  const allFbProgramClasses = getFbProgramClasses(firebaseData)
  const filter = { isFocusClass: true }
  if (level !== null) {
    filter['level'] = level
  }

  let focusClasses = _.filter(allFbProgramClasses, filter)
  focusClasses = _.filter(focusClasses, function (fc) {
    return !idsOfalreadyAssignedClassesToFilterOut.includes(fc.healthieCourseId)
  })

  return focusClasses.map((focusClass) => {
    return {
      ...focusClass,
      id: focusClass.healthieCourseId,
      pillar: firebaseData.pillars[focusClass.pillarId],
      goals: [],
    }
  })
}

export const useFirstProgramClassDate = () => {
  const { data: programClasses } = useProgramClasses()
  const courseMemberships = programClasses?.map((programClass) => programClass?.courseMembershipData)
  const firstProgramClass =
    courseMemberships?.sort(
      (a, b) =>
        moment(a.start_at, 'YYYY-MM-DD HH:mm:ss ZZ').toDate() - moment(b.start_at, 'YYYY-MM-DD HH:mm:ss ZZ').toDate()
    )[0] ?? null

  if (firstProgramClass) {
    return moment(firstProgramClass?.start_at, 'YYYY-MM-DD HH:mm:ss ZZ').startOf('day').toISOString()
  }

  return null
}

export const useCurrentProgramClass = () => {
  const { data = [], loading } = useProgramClasses()

  if (!data.length || loading) {
    return {
      item: {
        data: {
          id: null,
        },
      },
      index: null,
    }
  }

  return getCurrentProgramClass(data)
}

export const usePreviousProgramClass = () => {
  let { data = [], loading } = useProgramClasses()
  const { item } = useCurrentProgramClass()

  data = data.filter((item) => !item.isSectionSeparator)
  const index = data?.findIndex((i) => i?.courseMembershipData?.id === item?.courseMembershipData?.id) ?? null

  if (!data.length || loading || !index) {
    return {
      item: {
        data: {
          id: null,
        },
      },
      index: null,
    }
  }

  return { item: data[index - 1], index: index - 1 }
}

/**
 *
 * @param {*} sectionedData (prepopulated from Healthie - course membership)
 * @param {*} firebaseData - full firebase data default program template
 */
const addMissingProgramClasses = (sectionedData, firebaseData) => {
  // list of program classes from firebase
  const fbProgramClasses = getFbProgramClasses(firebaseData)

  // Get ids of courses from Healthie
  const idsFoundInHealthie = _.transform(
    sectionedData,
    function (result, item) {
      const ids = _.map(item.data, 'id')
      result.push(...ids)
    },
    []
  )

  // find classes that are not assigned in healthie and are not focus classes
  const missingProgramClasses = fbProgramClasses.filter((fbProgramClass) => {
    return !fbProgramClass.isFocusClass && !idsFoundInHealthie.includes(fbProgramClass.healthieCourseId)
  })

  sectionedData.forEach((level, index) => {
    const assignedFocusClasses = _.filter(level.data, { isFocusClass: true })
    const assignedPlaceholders = _.filter(level.data, { isFocusClassPlaceholder: true })
    const howManyPlaceholdersLeftToBeAdded =
      level.focusClassesCount - (assignedFocusClasses.length + assignedPlaceholders.length)
    const levelNotAssignedPlaceholders = _.filter(missingProgramClasses, {
      level: index + 1,
      isFocusClassPlaceholder: true,
    })

    const placeholdersToBeAdded = _.takeRight(levelNotAssignedPlaceholders, howManyPlaceholdersLeftToBeAdded)
    const allOtherMissingClasses = _.filter(missingProgramClasses, function (item) {
      return item.level === index + 1 && !item.isFocusClassPlaceholder
    })
    let allToBeAdded = [...allOtherMissingClasses, ...placeholdersToBeAdded]
    allToBeAdded = allToBeAdded
      .map((fbProgramClass) => {
        return {
          course: {
            id: fbProgramClass.healthieCourseId,
            notInHealthie: true,
          },
        }
      })
      .map((fbProgramClass) => getFullProgramClassData(fbProgramClass, firebaseData))
    level.data.push(...allToBeAdded)
  })
}

/**
 * Get Program Classes SectionList format array
 * @param  {Array} healthieCourseMemberships
 * @param  {Object} firebaseData
 * @param  {{dateNow: number | string}} options
 * @typedef {Array<{ data: Array<ProgramClass>, title: string }>} ProgramClassSectionList
 * @return {ProgramClassSectionList}
 */
const getSectionedProgramClasses = (healthieCourseMemberships, firebaseData, options = { dateNow: null }) => {
  const firebaseClasses = getFbProgramClasses(firebaseData)
  const sectionedData = _.transform(
    firebaseClasses,
    function (result, item) {
      const levelIndex = parseInt(item.level, 10) - 1

      if (!result[levelIndex] && !isNaN(levelIndex)) {
        result[levelIndex] = {
          title: `Level ${item.level}`,
          data: [],
          focusClassesCount: item.isFocusClassPlaceholder ? 1 : 0,
        }
      } else if (item.isFocusClassPlaceholder) {
        result[levelIndex]['focusClassesCount'] += 1
      }
    },
    []
  )

  const fireabseFocusClassesPlaceholders = _.filter(firebaseClasses, { isFocusClassPlaceholder: true })

  const focusClassesCountPerLevel = _.transform(
    fireabseFocusClassesPlaceholders,
    function (result, item) {
      if (!result[item.level]) {
        result[item.level] = 1
      } else {
        result[item.level] += 1
      }
    },
    {}
  )

  const placeProgramClassInCorrectSection = (programClass) => {
    if (!programClass) {
      return
    }

    const levelIndex = parseInt(programClass.level, 10) - 1
    sectionedData[levelIndex].data.push(programClass)
  }

  const now = options.dateNow || getCurrentDate()

  healthieCourseMemberships.forEach((courseMembership) => {
    const programClassData = getFullProgramClassData(courseMembership, firebaseData)
    placeProgramClassInCorrectSection(programClassData)
  })

  addMissingProgramClasses(sectionedData, firebaseData)

  // missingProgramClasses.forEach(placeProgramClassInCorrectSection)

  const sortedClasses = sectionedData
    .map((section) => section.data)
    .flat()
    .sort((a, b) => {
      if (a.startAt && b.startAt) {
        return moment(a.startAt).diff(moment(b.startAt), 'seconds')
      } else if (a.startAt) {
        return -1
      } else if (b.startAt) {
        return 1
      }

      return a.startWeek - b.startWeek
    })

  const allProgramClasses = sortedClasses.map((programClass, index, programClasses) => {
    const nextProgramClass = programClasses[index + 1]

    let isCurrent = false

    const isSameOrAfter = moment(now).isSameOrAfter(moment(programClass?.startAt))
    const isBefore = moment(now).isBefore(moment(nextProgramClass?.startAt))

    const isStarted = isSameOrAfter

    const isLastAvailableHealthieProgramClass = programClass?.startAt && !nextProgramClass?.startAt

    if (nextProgramClass) {
      isCurrent = (isSameOrAfter && isBefore) || (isSameOrAfter && isLastAvailableHealthieProgramClass)
    } else {
      isCurrent = isSameOrAfter
    }

    return {
      ...programClass,
      isStarted,
      isCurrent,
      endAt: nextProgramClass?.startAt ? moment(nextProgramClass?.startAt).startOf('day').subtract(1, 'second') : null,
    }
  })
  const groupedByLevel = groupBy(allProgramClasses, 'level')

  return Object.entries(groupedByLevel).map((entry) => {
    const [level, data] = entry

    return {
      data: data,
      title: `Level ${level}`,
      focusClassesCount: focusClassesCountPerLevel[level],
    }
  })
}

/**
 * Get full available Program Class data
 * @param  {Array} healthieCourseMemberships
 * @param  {Object} firebaseData
 * @typedef {{
 *  data: {
 *    title: string,
 *    level: number,
 *    course_items: array,
 *    guides: Guide[],
 *    weeks: number[],
 *    isCurrent: boolean | undefined,
 *    isStarted: boolean | undefined,
 *    trackers: Object,
 *    ..rest
 *  }
 * }} ProgramClass
 * @return {}
 */
const getFullProgramClassData = (healthieCourseMembershipData = {}, firebaseData) => {
  const { course = {}, ...courseMembershipData } = healthieCourseMembershipData
  const firebaseProgramClass = getFbProgramClassById(firebaseData, course.id)
  if (!firebaseProgramClass) return null

  const weeks = []
  weeks.push(firebaseProgramClass.startWeek)

  if (firebaseProgramClass.durationInWeeks > 1) {
    for (let i = 0; i < firebaseProgramClass.durationInWeeks - 1; i++) {
      weeks.push(firebaseProgramClass.startWeek + i + 1)
    }
  }

  const data = {
    ...course,
    ...firebaseProgramClass,
    title: firebaseProgramClass.title || course.name,
    level: firebaseProgramClass.level,
    trackers: firebaseProgramClass.trackers,
    weeks,
    guides: Object.keys(firebaseProgramClass.guideIds || {}).map(mapGuides(firebaseData)),
    pillar: getFbPillarById(firebaseData, firebaseProgramClass.pillarId),
    suggestedGoals: Object.keys(firebaseProgramClass.goalIds || {}).map((goalId) => {
      return getFbGoalById(firebaseData, goalId)
    }),
  }

  const normalizedData = {
    id: data.id,
    title: data.title,
    level: data.level,
    guides: data.guides,
    pillarId: data.pillarId,
    pillar: data.pillar,
    startAt: courseMembershipData.start_at
      ? moment(getFormattedHealthieDate(courseMembershipData.start_at)).startOf('day').toDate()
      : null,
    startWeek: data.startWeek,
    durationInWeeks: data.durationInWeeks,
    suggestedGoals: data.suggestedGoals,
    isFocusClass: data?.isFocusClass || false,
    isFocusClassPlaceholder: data?.isFocusClassPlaceholder || false,
    courseMembershipId: courseMembershipData?.id || null,
    lessons: data.course_items,
    notInHealthie: courseMembershipData.start_at ? false : true,
  }

  return normalizedData
}

/**
 * Get current Program Class
 * @param  {Array<ProgramClass>} programClasses
 * @return {{item: ProgramClass, index: number}}
 */
const getCurrentProgramClass = (programClasses = []) => {
  if (!programClasses.length) return null

  const index = programClasses.findIndex((programClass) => {
    return programClass && programClass.isCurrent
  })

  return { item: programClasses[index], index }
}

/**
 * Get Program Class by Id
 * @param  {Array<ProgramClass>} programClasses
 * @param  {String} id
 * @return {{item: ProgramClass, index: number}}
 */
const getProgramClassById = (programClasses = [], id) => {
  if (!programClasses.length) return null

  const index = programClasses.findIndex((programClass) => {
    return programClass?.id === id
  })

  return { item: programClasses[index], index }
}

/**
 * Get Firebase Lesson by id
 * @param  {Object} firebaseData
 * @param  {String} id
 * @return {FirebaseProgramClass}
 */
const getFbLessonById = (firebaseData, id) => {
  const firebaseLessons = (firebaseData && firebaseData.lessons) || {}

  return Object.values(firebaseLessons).find((fbProgramClass) => fbProgramClass.healthieCourseItemId === id) || null
}

/**
 * Get healthie course items for arry map functions
 * @param  {Object} firebaseData
 * @return {Function}
 */
const mapCourseItems = (firebaseData) => (courseItem) => {
  const firebaseLesson = getFbLessonById(firebaseData, courseItem.id) || {}

  return {
    ...courseItem,
    ...firebaseLesson,
    title: firebaseLesson.title || courseItem.name,
  }
}

/**
 * @param  {Object} firebaseData
 * @param  {String} guideId
 * @typedef {{ id: String, title: String, programClassIds: Number[],  url: String}} Guide
 * @return {Guide}
 */
const mapGuides = (firebaseData) => (guideId) => {
  const guide = firebaseData.guides[guideId]

  if (!guide) return null

  const programClassIds = guide.programClassIds || {}

  return {
    ...guide,
    id: guideId,
    programClassIds: Object.keys(programClassIds),
  }
}

const removeDuplicatesFromProgramClass = async ({ duplicates, apolloClient }) => {
  let index = 0
  const localDuplicates = { ...duplicates }
  const courseMembershipIdsToDelete = []
  if (!Object.keys(localDuplicates).length) return

  const deleteCourseMembershipMutation = Object.keys(localDuplicates).reduce((aggr, courseId) => {
    let duplicatesArray = localDuplicates[courseId]
    duplicatesArray = duplicatesArray.sort(
      (courseA, courseB) => courseA.courseMembershipId - courseB.courseMembershipId
    )
    duplicatesArray.pop()
    if (!duplicatesArray.length) {
      return aggr
    }

    duplicatesArray.forEach((duplicatedClass) => {
      courseMembershipIdsToDelete.push(duplicatedClass.courseMembershipId)
      const deleteQuery = `deleteCourseMembership(input: {id: "${duplicatedClass.courseMembershipId}"}) {
            courseMembership {
                id
                __typename
            }
            messages {
                field
                message
                __typename
            }
            __typename
        }
      `
      aggr += `deleteCourseMembership${index}: ${deleteQuery}
      `
      index++
    })

    return aggr
  }, '')

  const deleteCourseMutationFinal = gql`mutation deleteMultipeCourseMemberships {${deleteCourseMembershipMutation}}`

  const deleteMutationResult = await apolloClient.mutate({
    mutation: deleteCourseMutationFinal,
  })

  console.log('Delete Mutation results ', { deleteMutationResult })

  return Promise.resolve(courseMembershipIdsToDelete)
}

const updateUserProgramClasses = async ({
  user,
  programClasses,
  originalProgramClasses,
  deassignedClasses,
  apolloClient,
}) => {
  const userGoals = []

  const createUserCourseMutation = programClasses.reduce((aggr, curr, index) => {
    const courseId = curr.id
    const originalClass = _.find(originalProgramClasses, { id: courseId })

    if (!curr.startAt) return aggr

    const courseStartAt = getHealthieDateFormat(curr.startAt)

    if (courseStartAt.toLowerCase().includes('invalid')) {
      return aggr
    }

    let startDateChanged = false
    if (!originalClass) {
      // means it's a focus class
      startDateChanged = true
    } else if (!originalClass.startAt) {
      // was asigned a date
      startDateChanged = true
    } else {
      // start date changed
      const diffInMinutes = moment(curr.startAt).diff(originalClass.startAt, 'minute')
      startDateChanged = Boolean(diffInMinutes)
    }

    if (startDateChanged) {
      console.log('--- NOT equal', { curr, originalClass })
    }

    if (!startDateChanged) {
      // if start date hasn't changed - just skip mutation
      return aggr
    }

    if (curr.goals) {
      curr?.goals.forEach((goal) => userGoals.push(goal))
    }

    const courseMembershipId = curr?.courseMembershipId || null

    const course_members = `[{ label: "${user.full_legal_name}",  value: "user-${user.id}" }]`

    const updateCourseInput = `{
        id: "${courseId}",
        start_at: "${courseStartAt}",
        course_members: ${course_members}
      }
    `

    const updateCourseMembershipInput = `{
        id: "${courseMembershipId}",
        start_at: "${courseStartAt}"      
      }
    `

    const updateCourseQuery = `updateCourse( input: ${updateCourseInput}) {
        course {
          __typename
          id
        }
        __typename
      }
    `

    const updateCourseMembershipQuery = `updateCourseMembership( input: ${updateCourseMembershipInput}) {
        courseMembership {
          __typename
          id
        }
        __typename
      }`

    const createOrUpdateQuery = courseMembershipId ? updateCourseMembershipQuery : updateCourseQuery

    return (
      aggr +
      `createUserCourse${index}: ${createOrUpdateQuery}
    `
    )
  }, '')

  if (deassignedClasses) {
    const deleteCourseMembershipMutation = deassignedClasses.reduce((aggr, curr, index) => {
      const courseMembershipId = curr?.courseMembershipId

      if (!courseMembershipId) {
        return aggr
      }

      const deleteQuery = `deleteCourseMembership(input: {id: "${courseMembershipId}"}) {
            courseMembership {
                id
                __typename
            }
            messages {
                field
                message
                __typename
            }
            __typename
        }
      `

      return (
        aggr +
        `deleteCourseMembership${index}: ${deleteQuery}
      `
      )
    }, '')
    const deleteCourseMutationFinal = gql`mutation deleteMultipeCourseMemberships {${deleteCourseMembershipMutation}}`

    const deleteMutationResult = await apolloClient.mutate({
      mutation: deleteCourseMutationFinal,
    })
    console.log('Delete Mutation results ', { deleteMutationResult })
  }

  // await updateUserGoals({ goals: userGoals, user, apolloClient })

  if (createUserCourseMutation !== '') {
    const createUserCourseMutationFinal = gql`mutation createMultipleUserCourses {${createUserCourseMutation}}`
    const mutationResult = await apolloClient.mutate({
      mutation: createUserCourseMutationFinal,
    })

    console.log('Mutation results ', { mutationResult })
  }

  // return mutationResult
  return Promise.resolve()
}

export const updateUserGoals = async ({ goals = [], goalIdsToRemove = [], user = { id: null }, apolloClient }) => {
  if (!user?.id) {
    return Promise.reject()
  }

  const updateUserGoalsMutation = goals.reduce((aggr, goal, index) => {
    if (!goal?.startOn) {
      return aggr
    }

    const repeat = goal.repeat || 'Daily'
    const endDate = moment(goal?.dueAt).format('YYYY-MM-DD')
    const startOn = moment(goal?.startOn).format('YYYY-MM-DD')

    const description = goal.description + '\n~~~\n' + goal.question + '\n~~~\ncourse_id: ' + goal.courseId

    const createGoal = `createGoal${index}: createGoal(
      input: {
        description: """${description}"""
        due_date: "${endDate}"
        name: "${goal.name}"
        repeat: "${repeat}"
        start_on: "${startOn}"
        user_id: "${user.id}"
      }
    ) {
      newGoal: goal {
        __typename
        id
      }
    }
  `

    const updateGoal = `updateGoal${index}: updateGoal(
      input: {
        description: """${description}"""
        due_date: "${endDate}"
        name: "${goal.name}"
        repeat: "${repeat}"
        start_on: "${startOn}"
        user_id: "${user.id}"
        id: "${goal.id}"
      }
    ) {
      goal {
        __typename
        id
        description
      }
    }
    `

    const updateOrCreate = goal.id ? updateGoal : createGoal

    return aggr + updateOrCreate
  }, '')

  const updateUserGoalsMutationFinal = gql`mutation updateMulitpleGoals{${updateUserGoalsMutation}}`

  const mutationResult = await apolloClient.mutate({
    mutation: updateUserGoalsMutationFinal,
  })

  return Promise.resolve(mutationResult)
}

export const resetUserProgramClasses = async ({ user, programClasses, apolloClient }) => {
  const createUserCourseMutation = programClasses.reduce((aggr, curr, index) => {
    const courseId = curr.id
    const courseMembershipId = curr?.courseMembershipId || null
    const course_members = `[{ label: "${user.full_legal_name}",  value: "user-${user.id}" }]`

    const updateCourseInput = `{
        id: "${courseId}",
        start_at: "",
        course_members: ${course_members}
      }
    `

    const updateCourseMembershipInput = `{
        id: "${courseMembershipId}",
        start_at: "",     
      }
    `

    const updateCourseQuery = `updateCourse( input: ${updateCourseInput}) {
        course {
          id
        }
      }
    `

    const updateCourseMembershipQuery = `updateCourseMembership( input: ${updateCourseMembershipInput}) {
        courseMembership {
          id
        }
      }`

    const createOrUpdateQuery = courseMembershipId ? updateCourseMembershipQuery : updateCourseQuery

    return (
      aggr +
      `createUserCourse${index}: ${createOrUpdateQuery}
    `
    )
  }, '')

  if (createUserCourseMutation !== '') {
    const createUserCourseMutationFinal = gql`mutation createMultipleUserCourses {${createUserCourseMutation}}`
    const mutationResult = await apolloClient.mutate({
      mutation: createUserCourseMutationFinal,
    })

    console.log('Mutation results ', { mutationResult })
  }

  // return mutationResult
  return Promise.resolve()
}

export {
  useProgramClassesQuery,
  useProgramClasses,
  getCurrentProgramClass,
  getProgramClassById,
  getFbProgramClassById,
  updateUserProgramClasses,
  removeDuplicatesFromProgramClass,
}
