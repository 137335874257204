import moment from 'moment'

/**
 * Get  working formatted healthie date in JavascriptCore engine
 * @param  {String} date
 * @return {String} parsed date to acceptable format
 */
export const getFormattedHealthieDate = (date = '') => {
  try {
    const days = date.substring(0, 10)
    const hours = date.substring(11, 19)
    const timezone = date.substring(20, 25)

    return [days, 'T', hours, timezone].join('')
  } catch (err) {
    console.warn(err)
    return date
  }
}

/**
 * @param  {String<Date> | Date} date
 * @return {String} date string
 */
export const getHealthieDateFormat = (date) => {
  try {
    return moment(date).toString()
  } catch (err) {
    console.warn(err)
    return date
  }
}

/**
 * @param  {String<Date> | Date} date
 * @return {String}
 */
export const getHealthieDay = (date) => {
  try {
    return moment(date).toString()
  } catch (err) {
    console.warn(err)
    return date
  }
}

/**
 * Get time difference in millisenconds between 2 dates
 * @param  {String<Date> | Date} lhs
 * @param  {String<Date>  | Date} rhs
 * @return {Number} difference in milliseconds
 */
export const getDifferenceInMilliseconds = (lhs, rhs) => {
  try {
    const timeA = moment(lhs)
    const timeB = moment(rhs)

    return timeA.diff(timeB)
  } catch (err) {
    console.warn(err)
    return 0
  }
}

/**
 * @param  {String<Date> | Date} date
 * @param  {Number} roundTo
 * @return {String<Date>}
 */
export const getRoundedDateTime = (date, roundTo = 30) => {
  const round = moment(date).minute() >= roundTo ? roundTo : 0

  return moment(date).minute(round).second(0).toDate()
}

/**
 * @param  {String<Date> | Date} date
 * @param  {Number} roundMinutesTo
 * @return {String<Date>}
 */
export const getHour = (date) => {
  try {
    const format = 'h:mm a'

    return moment(date).format(format)
  } catch (err) {
    console.warn(err)
    return date
  }
}

/**
 * @param  {Number} mins
 * @return {{hours: Number, minutes: Number}}
 */
export const getTimeFromMinutes = (mins) => {
  const hours = Math.floor(mins / 60) | 0
  const minutes = mins % 60 | 0

  return {
    hours,
    minutes,
  }
}

/**
 * @param  {String<Date> | Date} date
 * @return {String} date string - MMM Do YYYY, h:mm:ss a
 */
export const getFullDate = (date) => {
  const format = 'MMM Do YYYY, h:mm:ss a'

  try {
    return moment(date).format(format)
  } catch (err) {
    console.warn('getFullDate', err)
    return moment().format(format)
  }
}

/**
 * Get current date
 * @return {Date} date string
 */
export const getCurrentDate = () => {
  return moment().toDate()
}

/**
 * Get end of the days' day from date
 * @param  {String<Date> | Date} date
 * @param  {Number} days
 * @return {Date} date string
 */
export const getEndOfTheDay = (date, days) => {
  return moment(date).endOf('day').add(days, 'days').toDate()
}

/**
 * Get end of the days' day from date
 * @param  {String<Date> | Date} date
 * @param  {Number} days
 * @return {Date} date string
 */
export const getNextDaysDate = (date, days) => {
  return moment(date).add(days, 'days').toDate()
}

/**
 * @param  {String<Date> | Date} date
 * @param  {Number} days
 * @return {Date} date string - YYYY-MM-DD
 */
export const getFullYear = (date) => {
  const format = 'YYYY-MM-DD'

  try {
    return moment(date).format(format).trim()
  } catch (err) {
    console.warn('getFullYear', err)
    return moment().format(format)
  }
}

/**
 * Get time difference in full days between 2 dates
 * @param  {String<Date> | Date} lhs
 * @param  {String<Date>  | Date} rhs
 * @return {Number} difference in full days
 */
export const getDifferenceInFullDays = (lhs, rhs) => {
  try {
    const endOfTheDayA = moment(lhs).endOf('day')
    const endOfTheDayB = moment(rhs).endOf('day')

    return Math.abs(endOfTheDayA.diff(endOfTheDayB, 'days'))
  } catch (err) {
    console.warn(err)
    return 0
  }
}

/**
 * Check if date is today
 * @param  {String<Date>  | Date} date
 * @return {Boolean} date is today
 */
export const isDateToday = (date) => {
  return moment(date).isSame(moment(), 'days')
}

export const enumerateDaysBetweenDates = function (startDate, endDate) {
  const dates = []
  const currDate = moment(startDate).subtract(1, 'day').startOf('day')
  const lastDate = moment(endDate).endOf('day')

  while (currDate.add(1, 'days').diff(lastDate) <= 0) {
    dates.push(currDate.clone().toDate())
  }

  return dates
}

export const areDatesOverlapping = (startDate1, endDate1, startDate2, endDate2) => {
  return moment(startDate1).isSameOrBefore(endDate2) && moment(startDate2).isSameOrBefore(endDate1)
}
