import * as React from 'react'

const MindV1 = ({ size = 100, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M38.044 80.738H27.305V60.912c0-23.527 19.111-42.607 42.695-42.607s42.695 19.08 42.695 42.607c0 15.018-7.784 28.224-19.558 35.816l-1.056.68V122.695H52.74V103.52h-2.305c-2.483 0-5.215-.319-7.237-1.194-.992-.429-1.7-.943-2.15-1.508-.422-.529-.7-1.2-.7-2.153V80.738h-2.304z"
        fill="#148E86"
        stroke="#148E86"
        strokeWidth={4.61}
      />
      <path
        d="M103.103 60.248c4.685-4.716 4.867-12.698.183-17.414a11.94 11.94 0 00-16.965 0l-.436.44-.386-.389a11.94 11.94 0 00-16.965 0c-4.684 4.716-4.684 12.361 0 17.077l8.697 8.754a.94.94 0 00.08.091l7.735 7.677a.881.881 0 001.23.013l6.913-6.568c.412-.319 6.88-6.627 7.258-7.007l2.656-2.674z"
        fill="#FAB52F"
        stroke="#fff"
        strokeWidth={3.215}
      />
    </svg>
  )
}

export default MindV1
