import gql from 'graphql-tag'

export const CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      id
      first_name
      last_name
      avatar_url
      timezone
      google_fit {
        id
        last_sync_date
      }
    }
  }
`

export const GET_USER = gql`
  query getUser($id: ID) {
    user(id: $id) {
      __typename
      id
      full_legal_name
      avatar_url
      timezone
      dob
    }
  }
`

export const COURSE_MEMBERSHIPS = gql`
  query getCourseMemberships($offset: Int, $client_id: ID, $fetch_all: Boolean = true) {
    courseMemberships(offset: $offset, client_id: $client_id, fetch_all: $fetch_all) {
      __typename
      id
      created_at
      start_at
      course {
        __typename
        id
        name
        description
        preview_image_url
        start_date
        end_date
        course_items {
          id
          name
          course_id
          completed_course_item {
            id
          }
        }
      }
    }
  }
`

export const COURSE_ITEM = gql`
  query getCourseItem($id: ID, $course_membership_id: ID) {
    courseItem(id: $id, course_membership_id: $course_membership_id) {
      __typename
      id
      name
      course_id
      scheduled_release
      item_type
      item_id
      user_id
      course_name
      completed_memberships_count
      completed_course_item {
        id
        completed_item_type
        completed_item_id
        created_at
        __typename
      }
    }
  }
`

export const DOCUMENT = gql`
  query getDocument($id: ID, $course_id: ID) {
    document(id: $id, course_id: $course_id) {
      __typename
      id
      created_at
      display_name
      file_content_type
      extension
      expiring_url
    }
  }
`

export const FORM_ANSWERS = gql`
  query getFormAnswers($id: ID) {
    initialFormAnswers(custom_module_form_id: $id) {
      answer
      custom_module_id
      user_id
      __typename
    }
  }
`

export const DOCUMENTS = gql`
  query documents(
    $offset: Int
    $keywords: String
    $sort_by: String
    $folder_id: String
    $private_user_id: String
    $viewable_user_id: String
    $file_type: String
    $consolidated_user_id: String
    $file_types: [String]
    $user_id: ID
    $filter: String
    $provider_id: ID
    $should_paginate: Boolean
  ) {
    documentsCount(
      keywords: $keywords
      filter: $filter
      folder_id: $folder_id
      private_user_id: $private_user_id
      viewable_user_id: $viewable_user_id
      file_types: $file_types
      provider_id: $provider_id
      consolidated_user_id: $consolidated_user_id
      file_type: $file_type
    )
    documents(
      offset: $offset
      keywords: $keywords
      sort_by: $sort_by
      filter: $filter
      folder_id: $folder_id
      consolidated_user_id: $consolidated_user_id
      provider_id: $provider_id
      should_paginate: $should_paginate
      file_type: $file_type
      file_types: $file_types
    ) {
      ...DocumentRowFragment
      created_at(viewable_user_id: $consolidated_user_id)
      __typename
    }
    user(id: $user_id) {
      id
      full_name
      avatar_url
      __typename
    }
    folders(
      keywords: $keywords
      sort_by: $sort_by
      filter: $filter
      folder_id: $folder_id
      consolidated_user_id: $consolidated_user_id
      provider_id: $provider_id
    ) {
      ...FolderRowFragment
      created_at(viewable_user_id: $consolidated_user_id)
      __typename
    }
    foldersCount(
      keywords: $keywords
      filter: $filter
      folder_id: $folder_id
      consolidated_user_id: $consolidated_user_id
      provider_id: $provider_id
    )
    folder(id: $folder_id) {
      id
      name
      rel_user_id
      shared
      current_user_can_edit
      folder_path {
        id
        name
        __typename
      }
      current_user_can_edit
      __typename
    }
  }
  fragment DocumentRowFragment on Document {
    id
    shared
    display_name
    file_content_type
    extension
    expiring_url
    include_in_charting
    rel_user_id
    owner {
      id
      first_name
      full_name
      is_patient
      __typename
    }
    __typename
  }
  fragment FolderRowFragment on Folder {
    id
    name
    shared
    rel_user_id
    owner {
      id
      full_name
      is_patient
      __typename
    }
    __typename
  }
`

export const ENTRY = gql`
  query getEntry($id: ID) {
    entry(id: $id) {
      __typename
      id
      description
      category
      created_at
      type
      name
      viewed
      metric_stat
      added_by_user {
        id
        full_name
        avatar_url
      }
      source
      third_party_source
    }
  }
`

export const ENTRIES = gql`
  query getEntries($category: String, $start_datetime_range: String, $end_datetime_range: String) {
    entries(category: $category, start_datetime_range: $start_datetime_range, end_datetime_range: $end_datetime_range) {
      __typename
      id
      description
      category
      created_at
      type
      name
      viewed
      metric_stat
      added_by_user {
        id
        full_name
        avatar_url
      }
      source
      third_party_source
    }
  }
`

export const FOOD_ENTRIES = gql`
  query getFoodEntries($start_datetime_range: String, $end_datetime_range: String) {
    entries(
      start_datetime_range: $start_datetime_range
      end_datetime_range: $end_datetime_range
      entry_id: null
      keywords: null
      type: "FoodEntry"
    ) {
      added_by_user {
        id
        full_name
        avatar_url
        __typename
      }
      category
      created_at
      description
      ed_posthunger
      ed_posthunger_string
      ed_prehunger
      ed_prehunger_string
      emotions_string
      healthiness_info_hex_value
      id
      image_url
      metric_stat
      reflection
      name
      percieved_hungriness
      source
      third_party_source
      type
      viewed
      __typename
    }
  }
`

export const GOALS_DATA = gql`
  query goalsData(
    $user_id: ID
    $offset: Int
    $per_page: Int
    $sort_by: String
    $category: String
    $keywords: String
    $end_range: String
    $rel_goal_id: String
    $start_range: String!
    $status_filter: String
    $frequency_filter: String
    $date_range_is_selected: Boolean!
  ) {
    goalsData(
      offset: $offset
      user_id: $user_id
      sort_by: $sort_by
      per_page: $per_page
      category: $category
      keywords: $keywords
      end_range: $end_range
      rel_goal_id: $rel_goal_id
      start_range: $start_range
      status_filter: $status_filter
      frequency_filter: $frequency_filter
    ) {
      goals_count
      daily_goals_count
      weekly_goals_count
      goals_streak_count
      all_time_goals_count
      one_time_goals_count
      completed_goals_count
      all_goals_status_count
      not_completed_goals_count
      all_goals_frequency_count
      goals_overall_completion_rate_info {
        percent
        __typename
      }
      goals {
        id
        name
        repeat
        due_date
        start_on
        created_at
        description
        subgoals_count
        created_user_id
        is_used_as_template
        title_link
        is_completed_for_date(date: $start_range) @skip(if: $date_range_is_selected)
        completion_percentage_for_range(start_range: $start_range, end_range: $end_range)
          @include(if: $date_range_is_selected)
        streak_info(start_range: $start_range, end_range: $end_range) {
          count
          start_date
          end_date
          __typename
        }
        __typename
      }
      __typename
    }
  }
`

export const ONBOARDING_TRACKERS = gql`
  query getOnboardingTrackers($user_id: String, $entry_type: String = "MetricEntry") {
    weightEntries: entries(client_id: $user_id, type: $entry_type, category: "Weight") {
      category
      metric_stat
      __typename
    }
    waistEntries: entries(client_id: $user_id, type: $entry_type, category: "Waist Circumference") {
      category
      metric_stat
      __typename
    }
    bloodEntries: entries(client_id: $user_id, type: $entry_type, category: "Blood Pressure") {
      category
      subentries {
        metric_stat
      }
      __typename
    }
  }
`

export const GET_METRIC_DATA = gql`
  query getMetricsData($user_id: String, $start_date: String, $end_date: String) {
    metricGraphsData(user_id: $user_id, start_date: $start_date, end_date: $end_date) {
      category
      category_label
      data_points {
        created_at
        value
        entry_id
        __typename
      }
      min
      max
      __typename
    }
  }
`
// FYI: old query that fetches only one portion of metric data based on category
// export const GET_METRIC_DATA = gql`
//   query getMetricData(
//     $user_id: String
//     $category: String
//     $multiplier: String
//     $entry_type: String
//     $start_date: String
//     $end_date: String
//     $entry_id: ID
//   ) {
//     metricGraphData(
//       user_id: $user_id
//       category: $category
//       multiplier: $multiplier
//       entry_type: $entry_type
//       start_date: $start_date
//       end_date: $end_date
//     ) {
//       category
//       category_label
//       ytick_type
//       data_points {
//         created_at
//         value: flex_value
//         value_as_decimal: value
//         entry_id
//         __typename
//       }
//       min
//       max
//       __typename
//     }
//     selectedEntry: entry(id: $entry_id) {
//       ...EntryFragment
//       __typename
//     }
//   }

//   fragment EntryFragment on Entry {
//     id
//     description
//     category
//     created_at
//     type
//     name
//     metric_stat
//     percieved_hungriness
//     ed_prehunger_string
//     ed_posthunger_string
//     emotions_string
//     image_url
//     healthiness_info_hex_value
//     ed_prehunger
//     ed_posthunger
//     has_subentries
//     subentries {
//       id
//       category
//       type
//       description
//       metric_stat
//       __typename
//     }
//     __typename
//   }
// `

export const APPOINTMENTS = gql`
  query appointments(
    $user_id: ID
    $filter: String
    $sort_by: String
    $should_paginate: Boolean
    $offset: Int
    $is_active: Boolean
    $with_all_statuses: Boolean
  ) {
    appointmentsCount(user_id: $user_id, filter: $filter, is_org: true, is_active: $is_active)
    appointments(
      is_active: $is_active
      user_id: $user_id
      filter: $filter
      is_org: true
      sort_by: $sort_by
      should_paginate: $should_paginate
      offset: $offset
      with_all_statuses: $with_all_statuses
    ) {
      ...ClientAppointmentFragment
      __typename
    }
  }

  fragment ClientAppointmentFragment on Appointment {
    id
    date
    start
    end
    contact_type
    generated_token
    length
    location
    appointment_category
    client_confirmed
    minimum_advance_cancel_time
    minimum_advance_reschedule_time
    pm_status(check_for_client_cancel: true)
    add_to_gcal_link
    confirmed
    connected_chart_note_string
    form_answer_group {
      id
      name
      created_at
      custom_module_form {
        id
        name
        __typename
      }
      __typename
    }
    provider {
      id
      last_name
      first_name
      full_name
      first_name
      avatar_url
      phone_number
      appointment_setting {
        id
        ask_clients_to_confirm
        client_should_call_provider
        allow_clients_to_cancel_appt
        allow_clients_to_reschedule_appt
        cant_cancel_message
        cant_reschedule_message
        __typename
      }
      __typename
    }
    appointment_type {
      name
      id
      __typename
    }
    use_zoom
    zoom_join_url
    attendees {
      id
      full_name
      first_name
      avatar_url
      phone_number
      __typename
    }
    attended_clients {
      id
      user_id
      confirmed
      __typename
    }
    __typename
  }
`

export const NOTE_ENTRIES = gql`
  query notesQuery(
    $offset: Int
    $entry_id: String
    $keywords: String
    $client_id: String
    $category: String
    $type: String
    $group_id: String
    $sort_by: String
    $start_range: String
    $end_range: String
  ) {
    entries(
      offset: $offset
      entry_id: $entry_id
      keywords: $keywords
      client_id: $client_id
      category: $category
      type: $type
      group_id: $group_id
      sort_by: $sort_by
      start_range: $start_range
      end_range: $end_range
    ) {
      ...EntryFragment
      __typename
    }
  }

  fragment EntryFragment on Entry {
    id
    description
    category
    reflection
    created_at
    type
    name
    viewed
    metric_stat
    percieved_hungriness
    ed_prehunger_string
    ed_posthunger_string
    emotions_string
    image_url
    healthiness_info_hex_value
    ed_prehunger
    ed_posthunger
    has_subentries
    subentries {
      id
      category
      type
      description
      metric_stat
      __typename
    }
    poster {
      id
      full_name
      avatar_url
      last_activity
      __typename
    }
    added_by_user {
      id
      full_name
      avatar_url
      __typename
    }
    source
    third_party_source
    comments {
      ...CommentFragment
      __typename
    }
    __typename
  }

  fragment CommentFragment on Comment {
    id
    content
    created_at
    is_reaction
    poster {
      id
      full_name
      avatar_url
      __typename
    }
    __typename
  }
`
