import { createContext, useEffect, useState } from 'react'
import CONFIG from '@config/Config'

export const FirebaseDataContext = createContext({
  data: {},
  isLoading: true,
  isError: false,
})

const PROGRAM_DATA_LOCAL_KEY = 'PROGRAM_DATA'
const PROGRAM_DATA_VERSION_LOCAL_KEY = 'PROGRAM_DATA_VERSION'

function getFirebaseStoredData() {
  return JSON.parse(localStorage.getItem(PROGRAM_DATA_LOCAL_KEY))
}
function getStoredFiebaseDataVersion() {
  return localStorage.getItem(PROGRAM_DATA_VERSION_LOCAL_KEY)
}

async function getFirebaseRemoteVersion() {
  try {
    const response = await fetch(CONFIG.dataFunctions.versionURL, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'x-api-key': CONFIG.dataFunctions.key },
    })
    const json = await response.json()
    return json.version
  } catch (err) {
    console.log('getRemoteVersion error', err)
    return ''
  }
}

async function getFirebaseRemoteData() {
  try {
    const response = await fetch(CONFIG.dataFunctions.dataURL, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'x-api-key': CONFIG.dataFunctions.key },
    })
    const json = await response.json()

    return json.data
  } catch (err) {
    console.log('getRemoteVersion error', err)
    return ''
  }
}

function setStoredFirebaseStoredData(data) {
  try {
    localStorage.setItem(PROGRAM_DATA_LOCAL_KEY, JSON.stringify(data))
  } catch (err) {
    console.log('setStoredFirebaseStoredData error', err)
  }
}

function setStoredFirebaseStoredVersion(version) {
  try {
    localStorage.setItem(PROGRAM_DATA_VERSION_LOCAL_KEY, version)
  } catch (err) {
    console.log('setStoredFirebaseStoredVersion error', err)
  }
}

export const useFirebaseData = () => {
  const [data, setData] = useState(getFirebaseStoredData())
  const [loading, setLoading] = useState(data ? false : true)
  const [storedVersion, setStoredVersion] = useState(getStoredFiebaseDataVersion())
  const [remoteVersion, setRemoteVersion] = useState(null)

  useEffect(() => {
    getFirebaseRemoteVersion().then((version) => {
      setRemoteVersion(version)
    })
  }, [])

  useEffect(() => {
    if (remoteVersion && storedVersion !== remoteVersion) {
      getFirebaseRemoteData().then((data) => {
        console.log('---- Will update local version of Firebase data')
        setData(data)
        setLoading(false)
        setStoredFirebaseStoredData(data)
        setStoredFirebaseStoredVersion(remoteVersion)
        setStoredVersion(remoteVersion)
      })
    }
  }, [storedVersion, remoteVersion])

  return [data, loading, null]
}
