import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import ActivitySet1 from '@images/ActivitySet1'
import CalibrateSet1 from '@images/CalibrateSet1'
import FoodSet1 from '@images/FoodSet1'
import HealthSet1 from '@images/HealthSet1'
import MindSet1 from '@images/MindSet1'
import SleepSet1 from '@images/SleepSet1'

import ActivitySet2 from '@images/ActivitySet2'
import CalibrateSet2 from '@images/CalibrateSet2'
import FoodSet2 from '@images/FoodSet2'
import HealthSet2 from '@images/HealthSet2'
import MindSet2 from '@images/MindSet2'
import SleepSet2 from '@images/SleepSet2'
import WeightSet2 from '@images/WeightSet2'
import JournalSet2 from '@images/JournalSet2'

const pillarImages = {
  1: {
    'Emotional Health': MindSet1,
    Health: HealthSet1,
    Exercise: ActivitySet1,
    Food: FoodSet1,
    Sleep: SleepSet1,
    General: CalibrateSet1,
  },
  2: {
    'Emotional Health': MindSet2,
    Health: HealthSet2,
    Exercise: ActivitySet2,
    Food: FoodSet2,
    Sleep: SleepSet2,
    General: CalibrateSet2,
    Weight: WeightSet2,
    Journal: JournalSet2,
  },
}

const getPillarsImage = (name, imageSet) => {
  return pillarImages[imageSet][name] || pillarImages[1]['General']
}

// Pillars Images have 2 sets. Set 1 images are colorful. Ste 2 images are monochromatic
const APillarsImage = ({ imageSet = 1, name = 'General', ...props }) => {
  const PillarImage = useMemo(() => getPillarsImage(name, imageSet), [name, imageSet])

  return <PillarImage {...props} />
}

APillarsImage.propTypes = {
  name: PropTypes.oneOf(['Emotional Health', 'Health', 'Exercise', 'Food', 'Sleep', 'General', 'Weight', 'Journal'])
    .isRequired,
  imageSet: PropTypes.oneOf([1, 2]),
}

export default APillarsImage
